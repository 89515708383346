<template>
    <div class="report-container" :class="{ opened: openReport }">
        <div v-show="openReport" class="report toPrint" :class="$mq">
            <section class="section-header">
                <div class="generated_date">{{ generatedDate() }}</div>
                <div class="header">
                    <div class="brand" v-if="employee.avatar"><img :src="employee.avatar" alt="" /></div>

                    <div class="brand text" v-else>{{ employee.account_name }}</div>
                    <div class="brand alex">
                        <img class="image" src="~@/../public/img/logo_andy.png" alt="Andy" />
                    </div>
                </div>
                <div class="report-header">
                    <div class="report-info">
                        <template v-if="reportType == 1 || reportType == 4">
                            <div class="report-info-left">
                                <h2 class="report-location">{{ value.location_name }}</h2>
                                <h2 class="report-date" v-if="data.start_date" v-html="resumeDate(data)"></h2>
                                <h2 class="report-employee" v-if="reportType == 4 && data.employees">
                                    {{ data.employees[Object.keys(data.employees)[0]].name }}
                                </h2>
                                <h2 class="report-date">
                                    <span class="close-date" v-if="reportType == 4 && data.complete_date"
                                        >{{ $t('report.issue_closed', { date: data.complete_date.format('dddd DD MMM YYYY hh:ss') }) }}
                                        <span class="employee-name">
                                            <img class="avatar" :src="cssImage + 'avatar/' + data.employee_ref.avatar" />
                                            {{ data.employee_ref.name + ' ' + data.employee_ref.surname }}
                                        </span>
                                    </span>
                                </h2>
                            </div>
                        </template>
                        <template v-else-if="reportType == FORMAT.YESNO">
                            <div class="report-info-left">
                                <h2 class="report-location">{{ value.location_name }}</h2>
                                <h2 class="report-date" v-if="data.business_date" v-html="resumeDate(data.business_date['_i'])"></h2>
                                <h2 class="report-employee" v-if="data.employee_ref">{{ getEmployee(data.employee_ref.id).name }}</h2>
                            </div>
                            <div
                                v-show="reportType == 2"
                                class="status-audit"
                                :class="getColorState(value.data.actionplans_score)"
                                v-html="getStateAudits(value.data.actionplans_score)"
                            ></div>
                        </template>
                        <template v-else-if="reportType == 3">
                            <div class="report-info-left">
                                <h2 class="report-location">{{ data.location_name }}</h2>
                                <h2 class="report-date" v-if="data.complete_date" v-html="resumeDate(data.complete_date)"></h2>
                                <UserTag :userId="data.employee_ref.id" size="medium" styleOf="block" :hasAvatar="true" />
                            </div>
                        </template>

                        <!-- REPORT STATUS -->
                        <div
                            v-if="reportType == 3 && data && data.status == 0"
                            class="report-status intime"
                            v-tooltip="$t('nvgt.report.timing.intime_tooltip')"
                        >
                            {{ $t('template.general.status.template_draft') }}
                        </div>
                        <div
                            v-else-if="reportType == 1 && reportStatus == 'intime' && !data.complete_date"
                            class="report-status intime"
                            v-tooltip="$t('nvgt.report.timing.intime_tooltip')"
                        >
                            {{ $t('nvgt.report.timing.intime') }}
                        </div>
                        <div
                            v-else-if="reportType == 1 && reportStatus == 'future'"
                            class="report-status future"
                            v-tooltip="$t('nvgt.report.timing.future_tooltip')"
                        >
                            {{ $t('nvgt.report.timing.future') }}
                        </div>
                    </div>

                    <div class="report-data">
                        <h2 class="report-title" :class="reportType == 3 ? 'fullwidth' : false">{{ value.title }}</h2>
                        <div class="left">
                            <template v-if="reportType == 1">
                                <template v-if="data.total == 0">
                                    <div class="na">{{ $t('nvgt.report.answer_na') }}</div>
                                </template>
                                <template v-else>
                                    <div class="score-number">{{ data.completed }}/{{ data.total }}</div>
                                    <div v-if="reportStatus != 'future' && data.total != 0" class="score-percent" :class="scoreColors(data.score)">
                                        {{ data.score }}%
                                    </div>
                                </template>
                            </template>
                            <template v-if="reportType == 2">
                                <div class="score-audit">
                                    <div
                                        v-if="data.finalScore && data.finalScore.color"
                                        class="final-score-color"
                                        :style="{ backgroundColor: data.finalScore.color }"
                                    ></div>
                                    <span v-if="data.finalScore && data.score == 0">{{ data.finalScore.name }}</span>
                                    <span v-else-if="data.finalScore && data.score > 0">{{ data.finalScore.name }} ({{ data.score }}%)</span>
                                    <span v-else-if="data.status == 2 && data.score > 0">{{ data.score }}%</span>
                                    <span v-else-if="data.score">{{ data.score }}%</span>
                                </div>
                            </template>
                            <template v-else-if="reportType == 4">
                                <States :currentState="data.status" :issueID="$route.params.id"></States>
                            </template>
                        </div>
                    </div>
                    <div class="actionPlan-title">{{ $t('tools.template_sub.actionplan_title') }}</div>

                    <!-- <div class="report-alerts" v-if="reportType == 1">
                        <div v-if="data.resume && data.resume.is > 0" class="counter incidents">
                            <span>{{ data.resume.is }}</span
                            >{{ $tc('nvgt.report.alerts_incident_number', data.resume.is) }}
                        </div>
                        <div v-if="data.resume && data.resume.a > 0" class="counter alerts">
                            <span>{{ data.resume.a }}</span
                            >{{ $tc('nvgt.report.alerts_number', data.resume.a) }}
                        </div>
                        <div v-if="data.resume && data.resume.it > 0 && reportStatus != 'intime' && reportStatus != 'future'" class="counter uncompleted">
                            <span>{{ data.resume.it }}</span
                            >{{ $tc('nvgt.report.uncompleted_number', data.resume.it) }}
                        </div>
                    </div>
                    <div class="report-alerts" v-else-if="reportStatus != 'future' && reportType != 2 && showAnswers">
                        <div v-if="data.resume && data.resume.it > 0 && reportStatus != 'intime'" class="counter uncompleted">
                            <span>{{ data.resume.it }}</span
                            >{{ $tc('nvgt.report.uncompleted_number', data.resume.it) }}
                        </div>
                    </div> -->
                </div>
            </section>

            <div v-if="reportType == 4 && refTask && refTask.check_title">
                <div class="reference">
                    <div class="andy"></div>
                    <div class="message" v-html="$t('nvgt.report.task_reference', refTask)"></div>
                </div>
            </div>

            <div
                v-for="(answer, index, actionPlanNum) in dataObj.items"
                :key="index"
                v-if="answerShowing(answer, data) && answer.reference && answer.actionplans && Object.keys(answer.actionplans).length > 0"
                :class="{
                    answer: answer.type != FORMAT.TITLE && answer.type != FORMAT.SUBTITLE,
                    'answer-title': answer.type == FORMAT.TITLE,
                    'answer-subtitle': answer.type == FORMAT.SUBTITLE
                }"
            >
                <div v-for="section in data.sections" :key="section.id" v-if="data.sections && section.items[0] == answer.item_id" class="section">
                    <div class="section-title">{{ section.name }}</div>
                    <div class="section-score" v-if="reportType == 2 && hasToHaveScore(section)">{{ section.score }}%</div>
                </div>

                <div class="auditItem">
                    <div
                        class="content"
                        v-if="
                            answer.actionplans &&
                            Object.keys(answer.actionplans).length > 0 &&
                            answer.type != FORMAT.TITLE &&
                            answer.type != FORMAT.SUBTITLE &&
                            conditionsCompliance(answer) &&
                            ((!showAnswers && ((answer.value != null && answer.value != '') || answer.apply == '0')) || showAnswers) &&
                            ((!showNoApply && answer.apply == '1') || showNoApply)
                        "
                        :class="{
                            noCompleted: answer.value == '0',
                            extended: isExtended(answer),
                            child: answer.parent_id,
                            nsnr: !answer.planned_to_date && !answer.value && parseInt(answer.apply) && answer.type != FORMAT.MESSAGE,
                            na: parseInt(answer.apply) == 0,
                            postponed: answer.planned_to_date,
                            'ans-intime': reportStatus == 'intime',
                            'ans-future': reportStatus == 'future'
                        }"
                        :data-type="answer.type"
                    >
                        <!-- AUDITS ITEM POINTS (ONLY MOBILE) -->
                        <div
                            v-if="
                                isMobile &&
                                isAuditItemWithPoints &&
                                answer.param &&
                                ((!showAnswers && ((answer.value != null && answer.value != '') || answer.apply == '0')) || showAnswers) &&
                                ((!showNoApply && answer.apply == '1') || showNoApply)
                            "
                            class="item-points-container"
                        >
                            <div class="item-points" :class="{ 'error-background': answer.score !== answer.param.total }">
                                <p>
                                    <span class="number score">{{ answer.score }}</span>
                                    <span class="number">/</span>
                                    <span class="number">{{ answer.param.total }}</span>
                                </p>
                            </div>
                        </div>
                        <div
                            class="ans-top"
                            :class="{
                                notDone: !answer.employee && reportStatus == 'past' && data.type == '1'
                            }"
                        >
                            <!-- ITEM TITLE AND DESCRIPTION -->
                            <div class="statement" :class="{ 'full-width': !answer.value, 'smaller-width': answer.value }">
                                <div class="actionPlan-number">
                                    {{ getActionPlanNum(actionPlanNum + 1) }}
                                </div>
                                <!-- ITEM TITLE -->
                                <div class="titl" v-html="answer.title"></div>
                                <!-- ITEM DESCRIPTION -->
                                <div
                                    v-if="answer.description && previewOpts.desc"
                                    class="description"
                                    v-html="descriptionParse(answer.description)"
                                    :class="{ 'shortened-paragraph': isMobile && !descriptionIsExpanded(answer.item_id) }"
                                ></div>
                                <template v-if="isMobile && answer.description">
                                    <button v-if="!descriptionIsExpanded(answer.item_id)" class="see-more" @click="toggleDescriptionExtension(answer.item_id)">
                                        {{ $t('assets.tags.see_more') }}
                                    </button>
                                    <button v-else class="see-more" @click="toggleDescriptionExtension(answer.item_id)">
                                        {{ $t('assets.tags.see_less') }}
                                    </button>
                                </template>
                            </div>
                            <!-- ITEM RESPONSE -->
                            <div class="response">
                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-if="parseInt(answer.apply) == 0"
                                    :data-value="answer.value"
                                >
                                    <div class="na">{{ $t('nvgt.report.answer_na') }}</div>
                                </div>
                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="answer.planned_to_date"
                                    :data-value="answer.value"
                                >
                                    <div class="postponed">{{ $t('nvgt.report.answer_postposed') }}</div>
                                </div>
                                <div
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="answer.type === FORMAT.MULTIPLE && answer.value"
                                    :data-value="answer.value"
                                >
                                    <div class="selector-container">
                                        <span
                                            v-if="getMultipleValue(answer).v"
                                            class="selector-color"
                                            :style="{ background: getMultipleValue(answer).c }"
                                            :class="[{ 'no-color': !getMultipleValue(answer).c }]"
                                        ></span>
                                        <span @click="showFullDesc = !showFullDesc" :class="{ fullDescription: showFullDesc }" class="selector-item">{{
                                            getMultipleValue(answer).v
                                        }}</span>
                                    </div>
                                </div>

                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="(answer.type == FORMAT.CHECK || answer.type == FORMAT.DOCUMENT) && answer.value"
                                    :data-value="answer.value"
                                >
                                    <div class="value" :class="[answer.value && (answer.value == 1 || answer.value == 'true') ? 'checked' : 'unchecked']"></div>
                                </div>
                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="(answer.type == FORMAT.YESNONC && answer.value) || (answer.type == FORMAT.YESNO && answer.value)"
                                    :data-value="answer.value"
                                >
                                    {{ answer.value && answer.value == 0 ? $t('nvgt.report.answer_no') : '' }}
                                    {{ answer.value && answer.value == 1 ? $t('nvgt.report.answer_yes') : '' }}
                                    {{ answer.value && answer.value == 2 ? $t('nvgt.report.answer_nsnc') : '' }}
                                </div>

                                <div
                                    class="response"
                                    :class="[{ warningRes: reportStatus == 'past' && data.type == '1' }]"
                                    v-else-if="answer.type == FORMAT.PHOTO && answer.value"
                                >
                                    <template v-if="answer.value.split(',').length == 1">
                                        <div class="image" @click="openGallery(answer.value.split(',')[0], resumeImages, answer)">
                                            <cld-image class="image" :publicId="answer.value" type="fetch" loading="lazy">
                                                <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                            </cld-image>
                                            <span
                                                class="image-resume-name"
                                                v-if="showImagesResume && haveResumeOfImages && resumeOfImages[answer.item_id].resumeTitle"
                                                >{{ resumeOfImages[answer.item_id].resumeTitle }}</span
                                            >
                                        </div>
                                    </template>
                                    <template v-if="answer.value.split(',').length > 1">
                                        <div class="image" v-for="img in answer.value.split(',')" :key="img" @click="openGallery(img, resumeImages, answer)">
                                            <cld-image class="image" :publicId="img" type="fetch" loading="lazy">
                                                <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                            </cld-image>
                                            <span
                                                class="image-resume-name"
                                                v-if="showImagesResume && haveResumeOfImages && resumeOfImages[answer.item_id][img].resumeTitle"
                                                >{{ resumeOfImages[answer.item_id][img].resumeTitle }}</span
                                            >
                                        </div>
                                    </template>
                                </div>

                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="answer.type == FORMAT.SIGNATURE && answer.value"
                                >
                                    <div class="image" @click="openMedia(answer.value)">
                                        <cld-image class="image" :publicId="answer.value" type="fetch" loading="lazy">
                                            <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                        </cld-image>
                                    </div>
                                    <div v-if="answer.type == FORMAT.SIGNATURE && answer.config.require_emp" class="employee">{{ answer.employee.name }}</div>
                                </div>

                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="(answer.type == FORMAT.NUMBER || answer.type == FORMAT.TEMPERATURE) && answer.value && answer.value != '-'"
                                    :data-value="answer.value"
                                >
                                    {{ answer.value }} {{ answer.config && answer.config.units ? answer.config.units : '' }}
                                </div>
                                <div
                                    class="response no-value"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="(answer.type == FORMAT.NUMBER || answer.type == FORMAT.TEMPERATURE) && answer.value == '-'"
                                >
                                    {{ $t('popup.default.no_value') }}
                                </div>
                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="answer.type == FORMAT.DATE && answer.value"
                                >
                                    {{ moment(answer.value).format('DD/MM/YYYY') }}
                                </div>
                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="answer.type == FORMAT.DATETIME && answer.value"
                                >
                                    {{ moment(answer.value).format('DD/MM/YYYY, HH:mm') }}
                                </div>
                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="answer.type == FORMAT.EMAIL && answer.value == 1"
                                    :data-value="answer.value"
                                >
                                    {{ $t('nvgt.report.mail_sended') }}
                                </div>
                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="answer.type == FORMAT.EMAIL && (answer.value == 0 || answer.value == false || answer.value == null)"
                                >
                                    {{ $t('nvgt.report.mail_no_sended') }}
                                </div>

                                <div
                                    class="response textarea"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                    v-else-if="answer.type == FORMAT.TEXTAREA && answer.value"
                                    :data-value="answer.value"
                                >
                                    {{ answer.value }}
                                </div>

                                <div
                                    class="response"
                                    :class="{ warningRes: reportStatus == 'past' && data.type == '1', answered: reportStatus == 'past' }"
                                    v-else-if="[1, 2, 3, 5, 6, 7, 13, 14, 15, 16, 17].indexOf(answer.type) == -1 && answer.value"
                                    :data-value="answer.value"
                                >
                                    {{ answer.value }}
                                </div>

                                <!-- UNCOMPLETED -->
                                <div
                                    class="response uncompleted"
                                    :class="{
                                        warningRes: reportStatus == 'past' && data.type == '1',
                                        'uncompleted-text': reportStatus == 'past',
                                        answered: reportStatus == 'past'
                                    }"
                                    v-else
                                    v-tooltip="
                                        reportStatus == 'intime' || reportStatus == 'future'
                                            ? $t('nvgt.report.timing.uncomplete_tooltip', {
                                                  v1: $t('template.general.item_type.' + getToolTypeName(answer.type))
                                              })
                                            : ''
                                    "
                                >
                                    {{ reportStatus == 'intime' || reportStatus == 'future' ? '' : $t('answer.unknown') }}
                                </div>
                            </div>
                        </div>

                        <!-- ITEM DETAILS -->
                        <div
                            class="ans-bottom"
                            :class="{ 'no-top-border': !answer.employee }"
                            v-if="
                                (reportStatus != 'intime' && reportStatus != 'future') ||
                                answer.message ||
                                answer.image ||
                                answer.complete_date ||
                                answer.planned_to_date
                            "
                        >
                            <!-- DONE BY / REALIZADO POR-->
                            <!-- Para que se vea 'Realizado por -' cuando no fue realizado: v-if="showDetails && (answer.employee || (!answer.employee && reportStatus != 'intime' && reportStatus != 'future'))" -->
                            <div v-if="showDetails && answer.employee" class="done" :class="answer.employee ? 'success' : 'error'">
                                <span class="done-by">{{ $t('nvgt.report.done_by') }}</span>
                                <!-- <span>{{answer.employee ? answer.employee.name : '-'}} {{answer.complete_date ? moment( answer.complete_date * 1000 ).format('HH:mm, DD MMM YYYY') : '' }}</span> -->
                                <span class="info">
                                    <template v-if="answer.employee">
                                        <UserTag :userId="answer.employee.id" size="small" styleOf="block" :hasAvatar="true" />
                                    </template>
                                    <template v-else> - </template>
                                    <!-- {{ answer.complete_date ? moment(answer.complete_date * 1000).format('HH:mm, DD MMM YYYY') : '' }} -->
                                </span>
                                <span class="date">
                                    {{ answer.complete_date ? moment(answer.complete_date * 1000).format('HH:mm, DD MMM YYYY') : '' }}
                                </span>
                                <div
                                    class="response out-schedule"
                                    v-if="showOutSchedule(answer) && showClock"
                                    v-tooltip="$t('nvgt.report.timing.overtime_tooltip')"
                                ></div>
                            </div>
                            <!-- REVIEWED BY / REVISADO POR -->

                            <div v-if="showDetails && answer.review" class="review" :class="answer.review ? 'success' : 'error'">
                                {{ $t('nvgt.report.review_by') }}
                                <span class="info">
                                    <template v-if="answer.review">
                                        <UserTag :userId="answer.review.employee.id" size="small" styleOf="block" :hasAvatar="true" />
                                    </template>
                                    <template v-else> - {{ $t('nvgt.report.review_pending') }} - </template>
                                </span>
                                <span class="date">
                                    {{ answer.review.complete_date ? moment(answer.review.complete_date * 1000).format('HH:mm, DD MMM YYYY') : '' }}
                                </span>
                            </div>

                            <div v-if="showNotes && (answer.message || answer.image) && previewOpts.note" class="note">
                                <div
                                    v-if="answer.message"
                                    class="message"
                                    :class="{ 'full-width': !answer.image }"
                                    v-html="descriptionParse(answer.message)"
                                ></div>
                                <div v-else class="message"></div>
                                <!-- <div v-if="answer.image" class="image" :style="{ backgroundImage: 'url(' + answer.image + ')' }" @click="openMedia(answer.image)"></div> -->
                                <div v-if="answer.image" class="image" @click="() => openGallery(answer.image, resumeImages, answer)">
                                    <cld-image v-if="answer.image" :publicId="answer.image" type="fetch" loading="lazy">
                                        <cld-transformation crop="fill" fetchFormat="webp" />
                                    </cld-image>
                                    <span
                                        class="image-resume-name"
                                        v-if="
                                            showImagesResume &&
                                            haveResumeOfImages &&
                                            resumeOfImages[answer.item_id] &&
                                            resumeOfImages[answer.item_id][answer.image] &&
                                            resumeOfImages[answer.item_id][answer.image].resumeTitle
                                        "
                                        >{{ resumeOfImages[answer.item_id][answer.image].resumeTitle }}</span
                                    >
                                </div>
                            </div>
                            <div v-if="answer.planned_to_date && showDetails" class="postponed-note">
                                <div class="postponed-info">
                                    <div class="postponed-day">
                                        {{ $t('nvgt.report.item_postposed') }} {{ moment(answer.planned_to_date * 1000).format('DD/MM/YYYY') }}
                                    </div>
                                    <div class="postponed-reason">
                                        {{ $t('answer.postpone.reason') }}:
                                        <template v-if="answer.planned_reason_id">
                                            {{ optionsPostpose[answer.planned_reason_id].name }}
                                        </template>
                                        <template v-else> {{ optionsPostpose[0].name }} </template>
                                    </div>
                                    <div class="text" v-if="!answer.planned_reason_id" v-html="descriptionParse(answer.planned_reason_msg)"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span v-if="answer.type == FORMAT.TITLE || answer.type == FORMAT.SUBTITLE" v-html="answer.title"></span>
                <div v-if="answer.actionplans" class="actionPlan-asociado">{{ $t('template.general.associate_plan_of_action') }}:</div>
                <div v-if="answer.actionplans" class="auditItem">
                    <!-- Loop over each action plan in answer.actionplans -->
                    <div v-for="(actionPlan, actionPlanIndex) in Object.values(answer.actionplans)" :key="actionPlanIndex" class="actionplanItems">
                        <!-- Check if actionPlan.items exists before looping -->
                        <div v-if="actionPlan.items" v-for="(item, itemIndex) in actionPlan.items" :key="itemIndex">
                            <div
                                class="content"
                                v-if="
                                    item.type != FORMAT.TITLE &&
                                    item.type != FORMAT.SUBTITLE &&
                                    conditionsCompliance(item) &&
                                    ((!showAnswers && ((item.value != null && item.value != '') || item.apply == '0')) || showAnswers) &&
                                    ((!showNoApply && item.apply == '1') || showNoApply)
                                "
                                :class="{
                                    noCompleted: item.value == '0',
                                    extended: isExtended(item),
                                    child: item.parent_id,
                                    nsnr: !item.planned_to_date && !item.value && parseInt(item.apply) && item.type != FORMAT.MESSAGE,
                                    na: parseInt(item.apply) == 0,
                                    postponed: item.planned_to_date,
                                    'ans-intime': reportStatus == 'intime',
                                    'ans-future': reportStatus == 'future'
                                }"
                                :data-type="item.type"
                            >
                                <div
                                    class="ans-top"
                                    :class="{
                                        notDone: !item.employee && reportStatus == 'past' && data.type == '1'
                                    }"
                                >
                                    <!-- ITEM TITLE AND DESCRIPTION -->
                                    <div class="statement" :class="{ 'full-width': !item.value, 'smaller-width': item.value }">
                                        <!-- ITEM TITLE -->
                                        <div class="titl" v-html="item.title"></div>
                                        <!-- ITEM DESCRIPTION -->
                                        <div
                                            v-if="item.description && previewOpts.desc"
                                            class="description"
                                            v-html="descriptionParse(item.description)"
                                            :class="{ 'shortened-paragraph': isMobile && !descriptionIsExpanded(item.id) }"
                                        ></div>
                                        <template v-if="isMobile && item.description">
                                            <button v-if="!descriptionIsExpanded(item.id)" class="see-more" @click="toggleDescriptionExtension(item.id)">
                                                {{ $t('assets.tags.see_more') }}
                                            </button>
                                            <button v-else class="see-more" @click="toggleDescriptionExtension(item.item_id)">
                                                {{ $t('assets.tags.see_less') }}
                                            </button>
                                        </template>
                                    </div>
                                    <!-- ITEM RESPONSE -->
                                    <div class="response">
                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-if="parseInt(item.apply) == 0"
                                            :data-value="item.value"
                                        >
                                            <div class="na">{{ $t('nvgt.report.answer_na') }}</div>
                                        </div>
                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="item.planned_to_date"
                                            :data-value="item.value"
                                        >
                                            <div class="postponed">{{ $t('nvgt.report.answer_postposed') }}</div>
                                        </div>
                                        <div
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="item.type === FORMAT.MULTIPLE && item.value"
                                            :data-value="item.value"
                                        >
                                            <div class="selector-container">
                                                <span
                                                    v-if="getMultipleValue(item).v"
                                                    class="selector-color"
                                                    :style="{ background: getMultipleValue(item).c }"
                                                    :class="[{ 'no-color': !getMultipleValue(item).c }]"
                                                ></span>
                                                <span @click="showFullDesc = !showFullDesc" :class="{ fullDescription: showFullDesc }" class="selector-item">{{
                                                    getMultipleValue(item).v
                                                }}</span>
                                            </div>
                                        </div>

                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="(item.type == FORMAT.CHECK || item.type == FORMAT.DOCUMENT) && item.value"
                                            :data-value="item.value"
                                        >
                                            <div
                                                class="value"
                                                :class="[item.value && (item.value == 1 || item.value == 'true') ? 'checked' : 'unchecked']"
                                            ></div>
                                        </div>
                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="(item.type == FORMAT.YESNONC && item.value) || (item.type == FORMAT.YESNO && item.value)"
                                            :data-value="item.value"
                                        >
                                            {{ item.value == 0 ? $t('nvgt.report.answer_no') : '' }}
                                            {{ item.value == 1 ? $t('nvgt.report.answer_yes') : '' }}
                                            {{ item.value == 2 ? $t('nvgt.report.answer_nsnc') : '' }}
                                        </div>

                                        <div
                                            class="response"
                                            :class="[{ warningRes: reportStatus == 'past' && data.type == '1' }]"
                                            v-else-if="item.type == FORMAT.PHOTO && item.value"
                                        >
                                            <template v-if="item.value.split(',').length == 1">
                                                <div class="image" @click="openGallery(item.value.split(',')[0], resumeImages, item)">
                                                    <cld-image class="image" :publicId="item.value" type="fetch" loading="lazy">
                                                        <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                                    </cld-image>
                                                    <span
                                                        class="image-resume-name"
                                                        v-if="showImagesResume && haveResumeOfImages && resumeOfImages[item.item_id].resumeTitle"
                                                        >{{ resumeOfImages[item.item_id].resumeTitle }}</span
                                                    >
                                                </div>
                                            </template>
                                            <template v-if="item.value.split(',').length > 1">
                                                <div
                                                    class="image"
                                                    v-for="img in item.value.split(',')"
                                                    :key="img"
                                                    @click="openGallery(img, resumeImages, item)"
                                                >
                                                    <cld-image class="image" :publicId="img" type="fetch" loading="lazy">
                                                        <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                                    </cld-image>
                                                    <span
                                                        class="image-resume-name"
                                                        v-if="showImagesResume && haveResumeOfImages && resumeOfImages[item.item_id][img].resumeTitle"
                                                        >{{ resumeOfImages[item.item_id][img].resumeTitle }}</span
                                                    >
                                                </div>
                                            </template>
                                        </div>

                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="item.type == FORMAT.SIGNATURE && item.value"
                                        >
                                            <div class="image" @click="openMedia(item.value)">
                                                <cld-image class="image" :publicId="item.value" type="fetch" loading="lazy">
                                                    <cld-transformation height="300" radius="3" crop="fill" fetchFormat="auto" />
                                                </cld-image>
                                            </div>
                                            <div v-if="item.type == FORMAT.SIGNATURE && item.config.require_emp" class="employee">{{ item.employee.name }}</div>
                                        </div>

                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="(item.type == FORMAT.NUMBER || item.type == FORMAT.TEMPERATURE) && item.value && item.value != '-'"
                                            :data-value="item.value"
                                        >
                                            {{ item.value }} {{ item.config && item.config.units ? item.config.units : '' }}
                                        </div>
                                        <div
                                            class="response no-value"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="(item.type == FORMAT.NUMBER || item.type == FORMAT.TEMPERATURE) && item.value == '-'"
                                        >
                                            {{ $t('popup.default.no_value') }}
                                        </div>
                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="item.type == FORMAT.DATE && item.value"
                                        >
                                            {{ moment(item.value).format('DD/MM/YYYY') }}
                                        </div>
                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="item.type == FORMAT.DATETIME && item.value"
                                        >
                                            {{ moment(item.value).format('DD/MM/YYYY, HH:mm') }}
                                        </div>
                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="item.type == FORMAT.EMAIL && item.value == 1"
                                            :data-value="item.value"
                                        >
                                            {{ $t('nvgt.report.mail_sended') }}
                                        </div>
                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="item.type == FORMAT.EMAIL && (item.value == 0 || item.value == false || item.value == null)"
                                        >
                                            {{ $t('nvgt.report.mail_no_sended') }}
                                        </div>

                                        <div
                                            class="response textarea"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1' }"
                                            v-else-if="item.type == FORMAT.TEXTAREA && item.value"
                                            :data-value="item.value"
                                        >
                                            {{ item.value }}
                                        </div>

                                        <div
                                            class="response"
                                            :class="{ warningRes: reportStatus == 'past' && data.type == '1', answered: reportStatus == 'past' }"
                                            v-else-if="[1, 2, 3, 5, 6, 7, 13, 14, 15, 16, 17].indexOf(item.type) == -1 && item.value"
                                            :data-value="item.value"
                                        >
                                            {{ item.value }}
                                        </div>

                                        <!-- UNCOMPLETED -->
                                        <div
                                            class="response uncompleted"
                                            :class="{
                                                warningRes: reportStatus == 'past' && data.type == '1',
                                                'uncompleted-text': reportStatus == 'past',
                                                answered: reportStatus == 'past'
                                            }"
                                            v-else
                                            v-tooltip="
                                                reportStatus == 'intime' || reportStatus == 'future'
                                                    ? $t('nvgt.report.timing.uncomplete_tooltip', {
                                                          v1: $t('template.general.item_type.' + getToolTypeName(item.type))
                                                      })
                                                    : ''
                                            "
                                        >
                                            {{ reportStatus == 'intime' || reportStatus == 'future' ? '' : $t('answer.unknown') }}
                                        </div>
                                    </div>
                                </div>

                                <!-- ITEM DETAILS -->
                                <div
                                    class="ans-bottom"
                                    :class="{ 'no-top-border': !item.employee }"
                                    v-if="
                                        (reportStatus != 'intime' && reportStatus != 'future') ||
                                        item.message ||
                                        item.image ||
                                        item.complete_date ||
                                        item.planned_to_date
                                    "
                                >
                                    <!-- DONE BY / REALIZADO POR-->
                                    <div v-if="showDetails && item.employee" class="done" :class="item.employee ? 'success' : 'error'">
                                        <span class="done-by"> {{ $t('nvgt.report.done_by') }} </span>
                                        <span class="info">
                                            <template v-if="item.employee">
                                                <UserTag :userId="item.employee.id" size="small" styleOf="block" :hasAvatar="true" />
                                            </template>
                                            <template v-else> - </template>
                                        </span>
                                        <span class="date">
                                            {{ item.complete_date ? moment(item.complete_date * 1000).format('HH:mm, DD MMM YYYY') : '' }}
                                        </span>
                                        <div
                                            class="response out-schedule"
                                            v-if="showOutSchedule(item) && showClock"
                                            v-tooltip="$t('nvgt.report.timing.overtime_tooltip')"
                                        ></div>
                                    </div>
                                    <!-- REVIEWED BY / REVISADO POR -->

                                    <div v-if="showDetails && item.review" class="review" :class="item.review ? 'success' : 'error'">
                                        {{ $t('nvgt.report.review_by') }}
                                        <span class="info">
                                            <template v-if="item.review">
                                                <UserTag :userId="item.review.employee.id" size="small" styleOf="block" :hasAvatar="true" />
                                            </template>
                                            <template v-else> - {{ $t('nvgt.report.review_pending') }} - </template>
                                        </span>
                                        <span class="date">
                                            {{ item.review.complete_date ? moment(item.review.complete_date * 1000).format('HH:mm, DD MMM YYYY') : '' }}
                                        </span>
                                    </div>

                                    <div v-if="showNotes && (item.message || item.image) && previewOpts.note" class="note">
                                        <div
                                            v-if="item.message"
                                            class="message"
                                            :class="{ 'full-width': !item.image }"
                                            v-html="descriptionParse(item.message)"
                                        ></div>
                                        <div v-else class="message"></div>
                                        <div v-if="item.image" class="image" @click="() => openGallery(item.image, resumeImages, item)">
                                            <cld-image v-if="item.image" :publicId="item.image" type="fetch" loading="lazy">
                                                <cld-transformation crop="fill" fetchFormat="webp" />
                                            </cld-image>
                                            <span
                                                class="image-resume-name"
                                                v-if="
                                                    showImagesResume &&
                                                    haveResumeOfImages &&
                                                    resumeOfImages[item.item_id] &&
                                                    resumeOfImages[item.item_id][item.image] &&
                                                    resumeOfImages[item.item_id][item.image].resumeTitle
                                                "
                                                >{{ resumeOfImages[item.item_id][item.image].resumeTitle }}</span
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="actionPlan-line"></div>
            </div>

            <div v-if="showImagesResume && Object.values(resumeOfImages).length > 0" class="image-resume">
                <div class="image-resume-title">{{ $t('nvgt.report.images_resume') }}</div>

                <div class="images-grid">
                    <div class="image-resume-container" v-for="image in parsedResumeImages" @click="openGallery(image.url, resumeImages, getDescri(image.url))">
                        <img :src="image.url" />
                        <span class="image-resume-name">{{ image.resumeTitle }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="openReport && false" class="report attachments" :class="{ toPrint: printOpts.attach }">
            <div class="brand"><img :src="account.avatar" alt="" /></div>

            <div class="brand alex">
                <span class="text">{{ $t('nvgt.report.generated_by') }} <span>Andy</span></span>
                <img class="image" src="~@/../public/img/alexhead_02.svg" alt="Andy" />
            </div>

            <h2 class="report-location">{{ value.location }}</h2>
            <h2 class="report-title">
                {{ value.title }}
                <small class="report-subtitle">{{ $tc('nvgt.report.attachment', 2) }}</small>
            </h2>
        </div>
    </div>
</template>

<script>
import States from '@/components/domain/issues/states.vue'
import { FORMAT } from '@/constants'
import i18n from '@/i18n'

import UserTag from '@/components/ui/UserTag'

export default {
    name: 'ReportActionPlan',
    props: {
        data: Object,
        userSearch: String,
        printOpts: Object,
        previewOpts: Object,
        reportType: Number,
        showAnswers: Boolean,
        showDetails: Boolean,
        showNoApply: Boolean,
        showClock: Boolean,
        showImagesResume: Boolean,
        showNotes: Boolean,
        showPoint: Boolean
    },
    components: { UserTag, States },
    data() {
        return {
            actionPlanNum: 0,
            openReport: true,
            extendedAnswers: [4, 10, 11],
            FORMAT: FORMAT,
            refTask: false,
            cssImage: css_image_url,
            showFullDesc: false,
            resumeOfImages: {},
            dataObj: {},

            // for mobile
            windowWidth: window.innerWidth,
            expandedDescriptionItemsIds: []
        }
    },
    computed: {
        account() {
            return this.$store.getters['login/getLocalAccount']
        },
        employee() {
            return this.$store.getters['login/getLocalEmployee']
        },
        value() {
            if (this.data.references || this.data.references != '') {
                this.loadReference(this.data)
            }
            this.dataObj = { ...this.data }
            this.dataObj.items = Object.fromEntries(Object.entries(this.data.items).map(([k, i]) => [k, { ...i, is_visible: true }]))
            return this.data
        },

        optionsTimeframes() {
            return this.$store.getters['tasks/getFilterTimeframe']()
        },

        optionsStates() {
            return this.$store.getters['issues/getFilterStates']()
        },

        optionsPostpose() {
            return this.$store.getters['getReasonPostpone']
        },
        reasonJustify() {
            return this.$store.getters['getReasonJustify']
        },
        parsedResumeImages() {
            const result = []
            Object.values(this.resumeOfImages).forEach((item) => {
                if (item.url) {
                    result.push({ url: item.url, resumeTitle: item.resumeTitle })
                } else {
                    result.push(
                        ...Object.values(item).map((img) => {
                            return { url: img.url, resumeTitle: img.resumeTitle }
                        })
                    )
                }
            })
            return result
        },
        resumeImages() {
            const self = this
            const items = Object.values(self.data.items)
            const result = []

            items.forEach((item) => {
                if (item.type === FORMAT.PHOTO && item.value) {
                    if (item.value.split(',').length == 1) {
                        result.push({
                            title: item.title,
                            message: item.message,
                            employee: item.employee,
                            complete_date: item.complete_date,
                            value: item.value
                        })
                    } else {
                        item.value.split(',').forEach((img) => {
                            result.push({
                                title: item.title,
                                message: item.message,
                                employee: item.employee,
                                complete_date: item.complete_date,
                                value: img
                            })
                        })
                    }
                }

                if (item.image) {
                    result.push({
                        title: item.title,
                        message: item.message,
                        employee: item.employee,
                        complete_date: item.complete_date,
                        value: item.image
                    })
                }
            })
            return result
        },
        haveResumeOfImages() {
            return Object.keys(this.resumeOfImages).length > 0 ? true : false
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        reportStatus() {
            if (this.data && this.reportType == 1) {
                const start = moment.unix(this.data.start_date)
                const end = moment.unix(this.data.end_date)

                if (moment().isBetween(start, end)) {
                    return 'intime'
                } else if (moment().isBefore(end)) {
                    return 'future'
                } else return 'past'
            }
        },
        isAuditItemWithPoints() {
            return this.reportType == 2 && this.showPoint
        }
    },
    methods: {
        loadImagesResume() {
            const IMAGE = i18n.t('template.general.item_type.PHOTO')

            this.resumeOfImages = {}
            let count = 0

            const items = Object.values(this.data.items)

            items.forEach((item) => {
                if (item.type == FORMAT.PHOTO && item.value) {
                    if (item.value.split(',').length == 1) {
                        count++

                        this.resumeOfImages[item.item_id] = { url: item.value, resumeTitle: `${IMAGE} ${count}` }
                    } else {
                        item.value.split(',').forEach((img) => {
                            count++
                            this.resumeOfImages = {
                                ...this.resumeOfImages,
                                [item.item_id]: {
                                    ...this.resumeOfImages[item.item_id],
                                    [img]: { url: img, resumeTitle: `${IMAGE} ${count}` }
                                }
                            }
                        })
                    }
                }

                if (item.image) {
                    count++
                    this.resumeOfImages = {
                        ...this.resumeOfImages,
                        [item.item_id]: {
                            ...this.resumeOfImages[item.item_id],
                            [item.image]: { url: item.image, resumeTitle: `${IMAGE} ${count}` }
                        }
                    }
                }
            })
        },

        getMultipleValue(answer) {
            const config = answer.config.values
            const value = answer.value
            const configAdapt = config
                .map((item) => {
                    return item.v ? { v: item.v, c: item.c } : { v: item, c: null }
                })
                .find((item) => item.v == value)
            return configAdapt
        },
        redirectToIssue(issueId) {
            this.$router.push({
                name: 'ReportIssue',
                params: {
                    id: issueId
                }
            })
        },
        hasToHaveScore(section) {
            if (section.total > 0 || section.total < 0) {
                return true
            }
            return false
        },
        descriptionParse(msg) {
            var result = msg
            if (result) {
                result = result.replace(/\n/gi, ' <br> ')
                result = result.replace(/([^\S]|^)(((https?\:\/\/)|(http?\:\/\/)|(www\.))(\S+))/gi, function (match, space, url) {
                    var hyperlink = url.startsWith('www') ? 'https://' + url : url
                    return space + '<a target="_blank" href="' + hyperlink + '">' + url + '</a>'
                })
                return result
            }
            return '-'
        },
        valueInPercentage(value, total) {
            var result = Math.round((value / total) * 100)
            if (isNaN(result)) {
                return 0
            }

            return result + '%'
        },

        loadReference(checklist) {
            if (checklist != undefined && checklist && checklist.references) {
                let self = this

                self.refTask = {
                    check_title: checklist.references.template_name,
                    check_date: moment(checklist.references.created_date * 1000).format('dddd DD MMM YYYY'),
                    task_title: checklist.references.item_name,
                    task_value: self.format(checklist.references.value, checklist.references.item_type, checklist.references.config),
                    task_description: checklist.references.item_description ? checklist.references.item_description : '-',
                    task_section: checklist.references.item_section ? checklist.references.item_section : '-'
                }
            } else {
                this.refTask = false
            }
        },

        format(value, type, config) {
            let response = value
            value = value ? JSON.parse(value) : value
            switch (parseInt(type)) {
                case FORMAT.CHECK:
                case FORMAT.YESNO:
                    response = value == 1 ? '✅' : '❌'
                    break
                case FORMAT.YESNONC:
                    if (value == 1) response = '✅'
                    else if (value == 2) response = '❌'
                    else response = this.$i18n.t('excel.nsnc')
                    break
                case FORMAT.DATE:
                    response = value ? moment(value).format('DD/MM/YYYY') : ''
                    break
                case FORMAT.DATETIME:
                    response = value ? moment(value).format('DD/MM/YYYY HH:mm') : ''
                    break
                case FORMAT.PHOTO:
                case FORMAT.SIGNATURE:
                    response = value ? '<img src="' + value + '"/>' : value
                    break
                case FORMAT.TEMPERATURE:
                    const configObj = JSON.parse(config)
                    const units = configObj.units
                    response = `${value}${units}`
                    break
                case FORMAT.EMAIL:
                    response = value ? this.i18n.t('excel.email.send') : this.i18n.t('excel.email.notSend')
                    break
            }
            return response
        },

        moment: function (date) {
            if (date) return moment(date)
            return moment()
        },

        generatedDate() {
            var now = moment()
            return this.$t('nvgt.report.generated_date', { c1: now.format('DD/MM/YYYY'), c2: now.format('HH:mm') })
        },

        resultClass(day) {
            var style = 'p' + day.score
            style += day.score == 100 ? ' success' : ' error'
            return style
        },

        scoreColors(score) {
            if (score >= 100) {
                return 'done'
            } else if (score > 75) {
                return 'progress'
            } else if (score > 25) {
                return 'warning'
            } else {
                return 'alert'
            }
        },

        stateFormat(iState) {
            let result = ''
            let status = this.optionsStates[iState]
            if (status && typeof status !== 'undefined') {
                if (status.color && status.color != null) {
                    result += "<span class='color' style='background-color: " + status.color + "'></span>"
                }
                result += "<span class='text'>" + status.name + '</span>'
            }

            return result
        },

        // AUDITS
        getStateAudits(score) {
            if (score === 0) {
                return this.$t('nvgt.t1.status.notStart')
            } else if (score < 100) {
                return this.$t('nvgt.t1.status.current')
            } else if (score == 100) {
                return this.$t('nvgt.t1.status.past')
            } else {
                return '-'
            }
        },

        getColorState(score) {
            var result = ''
            if (score == 100) {
                result = 'finalizada'
            }
            if (score < 100) {
                result = 'en_progreso'
            }
            if (score === 0) {
                result = 'sin_empieza'
            }
            return result
        },

        leftTime(valDate) {
            var num = valDate
            var hours = num / 60
            var rhours = Math.floor(hours)
            var minutes = (hours - rhours) * 60
            var rminutes = Math.round(minutes)
            var result = ''
            if (rhours == 0) {
                result = '+' + rminutes + ' min'
            } else if (rhours > 1) {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'horas'
                }
            } else {
                result = '+' + rhours + 'h y ' + rminutes + ' min'
                if (rminutes == 0) {
                    result = '+' + rhours + 'h'
                }
            }
            return result
        },

        // countAlerts(alerts) {
        //     var result = 0
        //     for (var index in alerts) {
        //         var item = alerts[index]
        //         result += alerts[index].length
        //     }
        //     return result
        // },

        resumeDate(day) {
            var result = ''
            if (this.reportType == FORMAT.CHECK && Object.keys(this.optionsTimeframes).length > 0) {
                result =
                    "<span class='date'>" +
                    moment(day.business_date * 1000).format('dddd DD MMM YYYY') +
                    "</span><span class='time'>" +
                    this.optionsTimeframes[day.timeframe_id].name +
                    (day.timeframe_id != 0
                        ? ' ' +
                          moment(
                              moment(moment().format('YYYY/MM/DD 00:00:00')).valueOf() + this.optionsTimeframes[day.timeframe_id].start_hour * 60 * 60 * 1000
                          ).format('HH:mm') +
                          ' - ' +
                          moment(
                              moment(moment().format('YYYY/MM/DD 00:00:00')).valueOf() + this.optionsTimeframes[day.timeframe_id].end_hour * 60 * 60 * 1000
                          ).format('HH:mm') +
                          '</span>'
                        : '')
            } else if (this.reportType == FORMAT.YESNO) {
                result = "<span class='date'>" + moment(day).format('dddd DD MMM YYYY') + '</span>'
            } else if (this.reportType == FORMAT.YESNONC) {
                result = "<span class='date'>" + moment(day * 1000).format('dddd DD MMM YYYY') + '</span>'
            } else if (this.reportType == FORMAT.TEXT) {
                result =
                    "<span class='date'>" +
                    moment(day.start_date * 1000).format('dddd DD MMM YYYY') +
                    ', ' +
                    moment(day.end_date * 1000).format('HH:mm') +
                    '</span>'
            }
            return result
        },

        answerShowing(answer, checklist) {
            return answer.is_visible
        },

        conditionsCompliance(answer) {
            if (answer.parent_id) {
                var compliance = true
                var parentItem = { ...this.data.items[answer.parent_id] }
                var answerCondition = { ...answer.conditions.values[Object.keys(answer.conditions.values)[0]] }

                // IF PARENT HAS NOT ANSWER
                if (parentItem.value == null) {
                    return false
                }

                // CHECK IF CONDITIONS ARE TRUE
                switch (answerCondition.operator) {
                    case '!empty':
                        if (parentItem.value === null) {
                            compliance = false
                        }
                        break

                    case '==':
                        if (parentItem.value != answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '!=':
                        if (parentItem.value == answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '>':
                        if (parentItem.value <= answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '>=':
                        if (parentItem.value < answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '<':
                        if (parentItem.value >= answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '<=':
                        if (parentItem.value > answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '=':
                        if (parentItem.value != answerCondition.value) {
                            compliance = false
                        }
                        break

                    case '<>':
                        var number1 = answerCondition.value.split(',')[0]
                        var number2 = answerCondition.value.split(',')[1]
                        var min = Math.min(number1, number2)
                        var max = Math.max(number1, number2)

                        if (!(parentItem.value > min && parentItem.value < max)) {
                            compliance = false
                        }
                        break

                    case '!<>':
                        var number1 = answerCondition.value.split(',')[0]
                        var number2 = answerCondition.value.split(',')[1]
                        var min = Math.min(number1, number2)
                        var max = Math.max(number1, number2)

                        if (parentItem.value > min && parentItem.value < max) {
                            compliance = false
                        }
                        break

                    default:
                        break
                }

                return compliance
            } else return true
        },

        conditionsAlerts(answer, alert) {
            let compliance = true
            // CHECK IF CONDITIONS ARE TRUE
            switch (alert.operator) {
                case '!empty':
                    if (answer.value === null) {
                        compliance = false
                    }
                    break

                case '==':
                    if (answer.value != alert.value) {
                        compliance = false
                    }
                    break

                case '!=':
                    if (answer.value == alert.value) {
                        compliance = false
                    }
                    break

                case '>':
                    if (answer.value <= alert.value) {
                        compliance = false
                    }
                    break

                case '>=':
                    if (answer.value < alert.value) {
                        compliance = false
                    }
                    break

                case '<':
                    if (answer.value >= alert.value) {
                        compliance = false
                    }
                    break

                case '<=':
                    if (answer.value > alert.value) {
                        compliance = false
                    }
                    break

                case '=':
                    if (answer.value != alert.value) {
                        compliance = false
                    }
                    break

                case '<>':
                    var number1 = alert.value.split(',')[0]
                    var number2 = alert.value.split(',')[1]
                    var min = Math.min(number1, number2)
                    var max = Math.max(number1, number2)

                    if (!(answer.value > min && answer.value < max)) {
                        compliance = false
                    }
                    break

                case '!<>':
                    var number1 = alert.value.split(',')[0]
                    var number2 = alert.value.split(',')[1]
                    var min = Math.min(number1, number2)
                    var max = Math.max(number1, number2)

                    if (answer.value > min && answer.value < max) {
                        compliance = false
                    }
                    break

                default:
                    break
            }

            return compliance
        },

        isExtended(answer) {
            return this.extendedAnswers.indexOf(answer.type) != -1 && answer.value
        },

        openMedia(ref) {
            this.$popup.media({ image: ref })
        },
        openGallery(ref, allImages, answer) {
            this.$popup.gallery({ imageUrl: ref, allImages: allImages, descri: answer })
        },
        openFeedback(message) {
            this.$popup.alert({
                title: this.$t('nvgt.report.feedback'),
                textSave: this.$t('popup.default.text_ok'),
                message: message
            })
        },
        getDescri(img) {
            if (!this.data || !this.data.items) {
                console.error('Data or items are not defined.')
                return null
            }

            let allItems = Object.values(this.data.items).flatMap((item) => {
                if (item.value && typeof item.value === 'string') {
                    return [item]
                } else if (Array.isArray(item)) {
                    return item.filter((imgObj) => imgObj.url && typeof imgObj.value === 'string')
                }
                return []
            })

            if (typeof img !== 'string') {
                console.error('Invalid argument: img should be a string.')
                return null
            }

            return allItems.find((obj) => obj.value && typeof obj.value === 'string' && obj.value.split(',').includes(img)) || null
        },
        getEmployee(id) {
            if (!id) {
                return false
            }
            var employee = this.$store.getters['employee/getAccountEmployee'](id)
            return employee
        },

        getToolTypeName(type) {
            return Object.keys(FORMAT).find((key) => FORMAT[key] === parseInt(type))
        },

        load() {
            // TODO - Get from store task/issue/... type
            this.data.def = {
                date_format: 'YYYY-MM-DD',
                hour_format: 'HH:mm',
                datetime_format: 'YYYY-MM-DD HH:mm',
                has_score: true,
                has_state: true,
                has_timing: true,
                has_mandatory: false,
                is_autosave: true,
                can_create: false,
                item_search: false,
                has_calendar: true,
                temporal_view: false,
                close_when_completed: false,
                auth_users: {
                    pin: 2,
                    review: 2,
                    task: 1
                },
                can_edit: {
                    title: true,
                    state: false,
                    inPast: false,
                    inTime: true,
                    inFuture: true
                },
                item: {
                    itemsTitle: [FORMAT.TITLE, FORMAT.SUBTITLE],
                    itemsImage: [FORMAT.PHOTO, FORMAT.SIGNATURE],
                    itemsInfo: [FORMAT.DOCUMENT, FORMAT.MESSAGE],
                    itemsExcluded: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.DOCUMENT, FORMAT.MESSAGE, FORMAT.LINK],
                    itemsVisibles: [FORMAT.TITLE, FORMAT.SUBTITLE, FORMAT.MESSAGE],
                    can_pospone: true,
                    rate: false,
                    can_review: true,
                    has_periodicity: true,
                    has_score: false
                }
            }

            if (this.data.type == 1 && Object.keys(this.optionsTimeframes).length <= 0) {
                this.$store.dispatch(`tasks/loadFilters`, {})
            }
        },
        formatDate(dateStr) {
            return moment(dateStr).format('dddd DD MMM YYYY')
        },
        formatTime(dateStr) {
            return moment(dateStr).format('HH:mm')
        },
        showOutSchedule(item) {
            // return true
            if (!item.complete_date || !this.data.timeframe_id || this.data.timeframe_id == 0) return false
            return this.data.schedule && item.complete_date && item.complete_date * 1000 > this.data.schedule.end.valueOf()
        },

        handleResize() {
            this.windowWidth = window.innerWidth
        },

        descriptionIsExpanded(item_id) {
            return this.expandedDescriptionItemsIds.includes(item_id)
        },
        toggleDescriptionExtension(item_id) {
            const index = this.expandedDescriptionItemsIds.indexOf(item_id)
            if (index > -1) {
                this.expandedDescriptionItemsIds.splice(index, 1)
            } else {
                this.expandedDescriptionItemsIds.push(item_id)
            }
        },

        getActionPlanNum(currentIndex) {
            let count = 0
            const keys = Object.keys(this.dataObj.items)

            for (let i = 0; i < currentIndex; i++) {
                const key = keys[i]
                const answer = this.dataObj.items[key]

                if (answer && answer.actionplans && Object.keys(answer.actionplans).length > 0) {
                    count++
                }
            }

            return count
        }
    },

    watch: {
        $mq(newValue, oldValue) {
            if (newValue == 'portrait') {
                this.$snackbar.close()
            } else {
                if (this.reportStatus == 'intime') {
                    this.$snackbar.open({ type: 'info', message: this.$t('nvgt.report.timing.intime_snackbar') })
                }

                if (this.data && this.data.type == 3 && this.data.status == '0') {
                    this.$snackbar.open({ type: 'info', message: this.$t('nvgt.report.timing.intime_snackbar') })
                }
            }
        },

        data(newData, oldData) {
            if (newData.id !== oldData.id) {
                this.loadImagesResume()
            }
        },

        userSearch(newSearch, oldSearch) {
            const noApply = this.$t('nvgt.report.answer_na')
            if (newSearch.length > 0) {
                const itemsArray = Object.values(this.data.items)
                const opts = {
                    0: this.$t('nvgt.report.answer_no'),
                    1: this.$t('nvgt.report.answer_yes'),
                    2: this.$t('nvgt.report.answer_nsnc')
                }

                const normalizeString = (str) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

                this.dataObj.items = itemsArray.filter((item) => {
                    const titleMatches = item.is_visible && normalizeString(item.title.toLowerCase()).includes(normalizeString(this.userSearch.toLowerCase()))
                    const optsMatches =
                        (item.type == 2 || item.type == 3) &&
                        opts[item.value] &&
                        normalizeString(opts[item.value].toLowerCase()).includes(normalizeString(this.userSearch.toLowerCase()))
                    const noApplyMatches =
                        item.is_visible && item.apply == '0' && normalizeString(noApply.toLowerCase()).includes(normalizeString(this.userSearch.toLowerCase()))
                    const valueMatches =
                        item.is_visible &&
                        item.value != null &&
                        item.type !== 2 &&
                        item.type !== 3 &&
                        item.type !== 16 &&
                        normalizeString(item.value.toLowerCase()).includes(normalizeString(this.userSearch.toLowerCase()))

                    return titleMatches || valueMatches || optsMatches || noApplyMatches
                })

                if (this.dataObj.items.length < 1 || newSearch.length < 1) {
                    this.$emit('toggleNoResultsMsg', true)
                } else {
                    this.$emit('toggleNoResultsMsg', false)
                }
                return this.dataObj
            } else {
                this.dataObj = this.data
            }
        }
    },

    created() {
        this.load()
        this.loadImagesResume()

        window.addEventListener('resize', this.handleResize)
    },

    mounted() {
        if (this.$mq != 'portrait') {
            if (this.reportStatus == 'intime') {
                this.$snackbar.open({ type: 'info', message: this.$t('nvgt.report.timing.intime_snackbar') })
            }

            if (this.data && this.data.type == 3 && this.data.status == '0') {
                this.$snackbar.open({ type: 'info', message: this.$t('nvgt.report.timing.intime_snackbar') })
            }
        }
    },
    beforeDestroy() {
        this.$snackbar.close()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<style lang="scss" scoped>
$img-error: img('error2_alert.svg');
$img-feedback: img('alert_info.svg');
$img-attach: img('attach_info.svg');
$img-note: img('comment_aaaaaa.svg');
$img-tick: img('checked_ok.svg');
$img-cross: img('error_alert.svg');

// $ans-value-bg: $color-neutral-50;

$radius: 3px;
$ans-width: 100%;

.report-container {
    @extend .scrollbar;
    @extend .scrollbar-white;
    text-align: center;
    // height: calc(90vh - 150px);
    height: calc(90vh - 54px);
    // margin: 60px 0px 30px 0px;
    margin: 0 0 30px 0;

    .image-resume-name {
        color: $color-black;
        font-family: $text;
        @include font-size('xs');
    }

    .image-resume {
        margin-top: 24px;
        .images-grid {
            padding: 1rem 0;
            @include border-radius($radius);
            width: $ans-width;
            margin: 0 auto 15px auto;
            border: 1px solid $color-neutral-300;
            // background-color: $color-neutral-50;
            @include display-flex();
            flex-wrap: wrap;
            justify-content: center;
            gap: 20px;

            .image-resume-container {
                width: 280px;
                @include display-flex();
                flex-direction: column;
                @include justify-content(flex-end);
                @include align-items(center);
                img {
                    width: 100%;
                }
            }
        }
    }
}

.day {
    margin-bottom: 60px;
}
.actionPlan-title {
    @include font-size('lg');
    font-family: $text-bold;
}
.actionPlan-asociado {
    font-size: 16px;
}
.report {
    @include box-shadow($h: 0px, $v: 0px, $b: 10px, $s: 0px, $c: rgba($color-neutral-200, 1));
    padding: 8px;
    width: 100%;
    min-height: 29.4cm;
    background-color: $color-white;
    border-radius: 4px;
    height: fit-content;

    @media (min-width: 1025px) {
        padding: 8px 27px 27px 27px;
        width: 21cm;
    }

    .generated_date {
        @include font-size('s');
        font-family: $text-medium;
        font-style: italic;
        width: 100%;
        text-align: center;
        padding-bottom: 5px;
        color: $color-neutral-600;
    }

    .header {
        overflow: hidden;
        .brand {
            height: 50px;
            display: inline-block;
            // float: left;

            &.text {
                @include display-flex();
                @include flex-direction(row);
                @include justify-content(flex-start);
                @include align-items();
                @include font-size('l');
                @include text-ellipsis();
                font-family: $text-bold;
                padding: 5px;
                max-width: calc(100% - 140px);
                height: 50px;
            }

            &.alex {
                text-align: right;
                float: right;

                .image {
                    height: 100%;
                    width: auto;
                    display: inline;
                    margin: 0;
                    @media (max-width: 767px) {
                        height: 74%;
                        margin-top: 8px;
                    }
                }
            }

            img {
                height: 100%;
                width: auto;
                @media (max-width: 767px) {
                    height: 96%;
                }
            }
        }
    }

    .report-header {
        margin: 40px 0 8px 0;
        width: 100%;
        @media (max-width: 767px) {
            margin-top: 8px;
        }

        .report-info {
            @include display-flex();
            @include align-items();
            // width: max-content !important;
            width: 100% !important;
            justify-content: space-between;
            // padding-bottom: 8px;
            padding-bottom: 12px;
            gap: 8px;

            .report-info-left {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                gap: 16px;

                @media (max-width: 767px) {
                    gap: 4px;
                }

                .report-date {
                    display: flex;
                    flex-direction: row;
                    gap: 4px;
                    flex-wrap: wrap;

                    @media (min-width: 768px) {
                        gap: 16px;
                    }
                }
            }

            .status-audit {
                display: inline-block;
                width: fit-content;
                padding: 3px 6px;
                border-radius: 3px;
                text-align: center;

                &.en_progreso {
                    @include background($color: $color-warning-500);
                    color: $color-white;
                }
                &.finalizada {
                    @include background($color: $color-neutral-500);
                    color: $color-white;
                }

                &.sin_empieza {
                    @include background($color: $color-neutral-500);
                    color: $color-white;
                }
            }

            .report-location,
            .report-date > span,
            .report-employee {
                @include font-size('sm');
                display: inline-block;

                line-height: 1.6;
                padding-left: 20px;
                width: fit-content;
                color: $color-neutral-600;

                font-family: $text;
            }
            .report-location {
                @include background($image: img('location_neutro_s40.svg'), $size: 15px, $position: left center);
                padding-left: 17px;

                font-size: 16px !important;
            }

            .report-date {
                & span {
                    &.date {
                        @include background($image: img('input_datetime_neutro_s40.svg'), $size: 15px, $position: left center);
                        text-transform: capitalize;
                        font-size: 16px !important;
                    }
                    &.time {
                        @include background($image: img('input_time_neutro_s40.svg'), $size: 15px, $position: left center);
                        padding-left: 18px;
                    }
                    &.close-date {
                        background: unset;
                        padding-left: unset;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 4px;

                        .employee-name {
                            display: flex;
                            align-items: center;
                            gap: 3px;
                        }

                        .avatar {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }

            .report-employee {
                padding-left: 17px;
            }

            .report-title {
                font-family: $text;
                width: 70%;
                padding-left: 12px;
            }

            .user-tag {
                width: fit-content;

                .avatar {
                    width: 20px;
                    height: 20px;
                }

                .name {
                    // @include font-size('sm');
                    color: $color-neutral-600;
                    // text-overflow: initial;
                    width: 100%;
                    font-size: 16px !important;
                    font-family: $text-light !important;
                }
            }

            .report-status {
                @include font-size(xs);
                @include border-radius(4px);
                font-family: $text-bold;
                color: $color-white;
                display: inline-block;
                width: fit-content;
                padding: 3px 6px;
                margin-left: 10px;

                &.intime {
                    background-color: $color-primary-500;
                }
                &.future {
                    background-color: $color-neutral-500;
                }
            }
        }

        .report-data {
            @include display-flex();
            @include flex-direction(row);
            @include justify-content(flex-end);
            @include align-items(flex-end);
            overflow: initial;
            width: 100%;
            height: fit-content;
            border-bottom: 1px solid $color-neutral-200;
            margin-bottom: 5px;

            .report-title {
                @include font-size('xl');
                color: $color-black;
                width: calc(100% - 150px);
                float: left;

                &.fullwidth {
                    width: 100%;
                }
            }

            .issue-closed {
                @include font-size('sm');
                font-family: $text;
                width: auto;
            }

            .score-number,
            .demora,
            .score-percent {
                @include font-size('ml');
                font-family: $text-bold;
                color: $color-black;
                display: inline-block;
                // margin: 10px 10px;

                @media (min-width: 901px) {
                    font-size: 26px;
                }
            }

            .na {
                @include align-items();
                @include border-radius(4px);
                @include text-ellipsis();
                @include background($image: img('error_main_s40.svg'), $size: 15px, $position: left 0px center);
                @include font-size('sm');
                padding: 3px 9px 3px 22px;
                text-align: center;
                // background-color: $color-primary-100;
                // padding: 3px 9px 3px 30px;
                font-family: $text-bold;
                color: $color-primary-500;
                width: min-content;
                margin: 0 auto;
            }

            .left {
                @include display-flex();
                @include justify-content(flex-end);
                align-self: flex-end;

                height: 100%;

                gap: 16px;

                .score-number {
                    font: $text-bold;
                    color: $color-black;
                }

                .na {
                    @include font-size('sm');
                    color: $color-black;
                    font-family: $text-bold;
                    width: min-content;
                    margin: 0;
                    float: right;
                }

                .score-percent {
                    font: $text-bold;
                    color: $color-black;

                    &.alert {
                        color: $color-error-500;
                    }
                    &.warning {
                        color: $color-warning-500;
                    }
                    &.progress {
                        color: $color-success-500;
                    }
                    &.done {
                        color: $color-success-500;
                    }
                }

                .score-audit {
                    width: fit-content;
                    display: flex;
                    gap: 0.4em;
                    align-items: center;

                    span {
                        font-size: 20px;
                    }

                    @media (min-width: 768px) {
                        span {
                            font-size: 24px;
                        }
                    }

                    .final-score-color {
                        width: 8px;
                        height: 8px;
                        aspect-ratio: 1/1;
                        border-radius: 50%;
                    }

                    span {
                        font-family: $text-bold;
                    }
                }

                .demora {
                    @include font-size('sm');
                    margin-bottom: 4px;
                    color: $color-error-500;
                    display: inline-block;
                }
                .status {
                    float: right;
                    background-color: $color-neutral-200;
                    padding: 3px 8px;
                    border-radius: 3px;
                    width: fit-content;
                    text-align: left;
                    margin-bottom: 4px;

                    span.text {
                        @include font-size('sm');
                        font: $text-bold;
                        color: $color-black;
                        padding-left: 0px;
                    }
                    .color {
                        margin-right: 5px;
                        border-radius: 100%;
                        display: inline-block;
                        height: 10px;
                        width: 10px;
                    }
                }
            }
        }

        .report-alerts {
            width: 100%;

            .counter {
                @include background($size: 15px, $position: left center);
                @include font-size(sm);
                font-family: $text;
                color: $color-neutral-600;
                padding-left: 20px;
                display: inline-block;
                margin-right: 15px;
                margin-bottom: 15px;

                & span {
                    font-family: $text;
                    color: $color-black;
                    margin-right: 5px;
                }
                &.notes {
                    background-image: img('task_note_neutro_s70.svg');
                }
                &.incidents {
                    background-image: img('task_warning_error_s10.svg');
                }
                &.alerts {
                    background-image: img('task_warning_warning_s10.svg');
                }
                &.errors {
                    background-image: img('task_issue_error_s10.svg');
                }
                &.uncompleted {
                    background-image: img('task_uncompleted_error_s10.svg');
                }
            }
        }
    }

    .reference {
        @include display-flex();
        @include border-radius(3px);
        border: 1px solid $color-primary-100;
        padding: 5px;
        background-color: $color-primary-100;

        .andy {
            @include background($image: img('alex_carita_07.svg'), $size: 50px, $position: center center);
            height: 50px;
            width: 50px;
            margin: 0 15px 0 5px;
        }

        .message {
            @include font-size(sm);
            width: calc(100% - 70px);
            font-family: $text-light;

            b,
            span {
                @include font-size(sm);
            }
        }
    }

    .justify {
        background-color: $color-secondary-100;
        @include border-radius(4px);
        padding: 6px;
        display: flex;
        align-items: center;
        gap: 0.2em;
        color: $color-black;

        .andy {
            @include background($image: img('alex_carita_07.svg'), $size: 50px, $position: center center);
            width: 89px;
            height: 50px;
            margin: 0;
            padding: 0.5em;
        }

        .justify-text,
        .justify-text b {
            font-size: 0.875rem;
            line-height: 140%;
            font-weight: lighter;
        }
    }

    .answer {
        width: 100%;
        display: block;
        clear: both;
        border-radius: 4px;
        page-break-inside: avoid !important;
        page-break-before: auto !important;

        .alert {
            @include display-flex();
            @include flex-direction(row);
            @include justify-content(flex-start);
            @include align-items();
            width: $ans-width;
            height: auto;
            margin: 0 auto 1px auto;
            text-transform: uppercase;
            position: relative;

            span {
                @include background($image: $img-error, $size: 12px, $position: left center);
                @include font-size(12px);
                display: inline-block;
                margin-right: 15px;
                padding-left: 14px;
                color: $color-error-500;
                font-family: $text;
                transition-duration: 0.2s;
            }
            .feedback {
                @include background($image: $img-feedback, $size: 12px, $position: left 3px center, $color: lighten($color-info-500, 40%));
                @include border-radius(3px);
                @include opacity(0.6);
                padding: 3px 6px;
                padding-left: 18px;
                margin-bottom: 6px;
                color: $color-info-800;
                cursor: pointer;
            }
            .attach {
                @include opacity(0.6);
                color: unset;
                background-image: $img-attach;
                color: $color-info-800;
                margin-right: 0;
                position: absolute;
                right: 0;
            }
            .attach {
                visibility: hidden;
            }
        }
        .auditItem {
            display: flex;
            width: 100%;

            .content {
                border-left: 5px solid #ccc;
            }
            .content.associatedActionPlan {
                border-left: 1px solid $color-neutral-300;
            }

            .actionplanItems {
                width: 100%;
                .content {
                    border-left: 1px solid #ccc;
                }
            }
            @media (min-width: 768px) {
                padding-bottom: 4px;
            }
        }

        .actionPlan-line {
            width: 100%;
            height: 1px;
            background-color: #ccc;
            margin: 8px 0;
        }
        .content {
            @include border-radius($radius);
            @include display-flex();
            @include flex-direction(row);
            @include flex-wrap(wrap);
            @include align-items();
            width: $ans-width;
            margin: 0 auto 15px auto;
            border: 1px solid $color-neutral-300;
            background-color: $color-white;
            position: relative;
            page-break-inside: avoid !important;

            .item-points-container {
                width: 100%;
                padding: 8px 8px 4px 8px;

                .item-points {
                    border-radius: 4px;
                    width: 80px;
                    height: 24px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-success-500;

                    p {
                        padding: 0px !important;
                    }

                    .number {
                        color: $color-white;
                        font-family: $text;
                    }

                    .score {
                        font-weight: bold;
                    }
                }

                .error-background {
                    background-color: $color-error-500;
                }
            }

            .display-none {
                display: none;
            }

            .ans-bottom {
                @include display-flex();
                @include flex-direction(row);
                @include flex-wrap(wrap);
                @include align-items();
                @include justify-content(space-between);
                width: 100%;
                .done {
                    background-color: $color-neutral-200;
                }
            }

            .no-top-border {
                border-top: none !important;
            }

            .ans-top {
                width: 100%;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-between;
                background-color: $color-white;

                @media (max-width: 767px) {
                    align-items: flex-start;

                    .response {
                        width: fit-content;

                        .uncompleted-text {
                            min-width: 120px;
                            width: 120px;
                            max-width: 120px;
                        }
                    }
                }
            }

            .answer-below {
                @media (max-width: 767px) {
                    display: flex;
                    flex-direction: column;
                }
            }

            .notDone {
                .warningRes {
                    color: $color-error-500 !important;
                }
            }

            .ans-bottom {
                @include border-radius($radius);
                border-top: 1px solid $color-neutral-300;
                // background-color: $color-neutral-50;
                .done-by {
                    color: $color-neutral-600;
                }
            }

            .statement {
                display: flex;
                width: calc(100% - 115px);
                max-width: calc(100% - 115px);
                padding: 4px 8px;
                transition-duration: 0.2s;
                .actionPlan-number {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $color-neutral-300;
                    border-radius: 50%;
                    width: 16px;
                    height: 16px;
                    font-size: 12px;
                    margin-right: 4px;
                    margin-top: 3px;
                }
                @media (max-width: 767px) {
                    width: 100%;
                    max-width: 100%;
                    flex: 1;
                }

                .see-more {
                    cursor: pointer;
                    background-color: inherit;
                    color: #222222;
                    padding: 0px;
                    margin: 0px;
                    font-size: 14px;
                    height: fit-content;
                }
            }

            .response {
                @include justify-content(flex-end);
                @include align-items();
                display: flex !important;
                text-align: right;
                // padding: 3px 6px 3px 3px;
                padding: 4px;
                font-family: $text-light;
                @include font-size('m');

                &.textarea {
                    white-space: pre-line;
                }

                div &.no-value {
                    width: 100px;
                    line-height: 1;
                    @include font-size('s');
                    text-transform: uppercase;
                    font-family: $text;

                    &:after {
                        content: '';
                    }
                }
                .selector-container {
                    width: 100%;
                    height: 100%;
                    max-height: 3em;
                    position: relative;
                    @include border-radius(3px);
                    @include font-size(0.875rem);
                    display: flex;
                    @include justify-content(flex-end);
                    align-items: center;
                    gap: 5px;
                    white-space: nowrap;
                    overflow: hidden;

                    .selector-item {
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                        height: 23px;
                    }
                    .fullDescription {
                        display: block;
                    }

                    .selector-color {
                        padding: 5px;
                        @include border-radius(100%);

                        .no-color {
                            display: none;
                        }
                    }
                }

                &.out-schedule {
                    @include background($image: img('clock_green.svg'), $size: 16px, $position: center);
                    @include border-radius($default-border-radius);
                    @include font-size(20px);
                    font-family: $text-bold;
                    width: 16px;
                    height: 22px !important;
                    margin-left: 4px !important;
                    margin: 0px !important;

                    &:hover {
                        background-color: $color-info-100;
                    }
                }
            }

            .titl {
                @include font-size('sm');
                font-family: $text-bold;
                font-size: inherit;
                color: $color-black;
                display: block;

                b {
                    @include font-size(14px);
                    color: #888;
                }

                ul {
                    list-style: disc;
                    padding: 5px 0;
                    padding-left: 30px;
                }

                li {
                    @include font-size(14px);
                    color: #888;
                }
            }

            .description {
                @include font-size('s');
                font-family: $text;
                // padding: 3px 6px 3px 0px;
                padding: 4px 8px 4px 0px;
                width: 100%;
                color: $color-black;

                @media screen and (max-width: 1024px) {
                    padding: 4px 0px;
                }

                b {
                    @include font-size('s');
                    color: #888;
                }

                ul {
                    list-style: disc;
                    padding: 5px 0;
                    padding-left: 30px;
                }

                li {
                    @include font-size('s');
                    color: #888;
                }

                strong {
                    @include font-size('s');
                }

                i {
                    @include font-size('s');
                }
            }

            // two lines text
            .shortened-paragraph {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .done,
            .review {
                @include font-size('s');
                @include display-inline-flex();
                align-items: center;

                font-family: $text;
                color: $color-black;
                width: 100%;

                padding: 3px 6px;
                flex-wrap: wrap;
                gap: 3px;

                span {
                    font-family: $text;
                    text-transform: none;
                }

                .info {
                    @include display-inline-flex();
                    font-family: $text;

                    .user-tag {
                        @include align-items(flex-start);
                        max-height: 20px;

                        max-width: 150px;

                        .avatar {
                            width: 18px;
                            height: 18px;
                        }

                        .name {
                            margin-left: 1px;

                            $color-black: #222222;
                        }
                    }
                }

                &.error {
                    color: $color-neutral-500;

                    .info {
                        @media (max-width: 767px) {
                            font-size: 14px;
                        }
                    }

                    .info {
                        color: $color-neutral-500;
                    }
                }

                .date {
                    margin-left: 0px;
                    $color-black: #222222;
                    font-family: $text-light;

                    @media (max-width: 767px) {
                        font-size: 14px !important;
                    }
                }

                @media (max-width: 767px) {
                    font-size: 14px !important;
                }
            }

            .review {
                padding-top: 0px;
            }

            .note,
            .postponed-note {
                @include background($size: 18px, $position: left 6px center);
                @include border-radius(3px);
                @include display-flex();
                @include align-items();
                width: 100%;
                max-width: calc(100% - 18px);
                height: auto;
                min-height: 30px;
                padding: 5px 5px 5px 32px;
                margin: 6px 9px;
                border: 1px solid $color-neutral-300;
                background-color: $color-white;
                gap: 8px;
            }
            .note {
                background-image: img('task_note_neutro_s70.svg');

                .message {
                    @include font-size('s');
                    color: $color-black;
                    font-family: $text;
                    width: calc(100% - 30px);
                    display: inline-block;
                }

                .full-width {
                    width: 100% !important;
                }

                .image {
                    @include display-inline-flex();
                    @include justify-content(center);
                    @include align-items(center);
                    flex-direction: column;
                    overflow: hidden;
                    cursor: pointer;
                    width: 60px;

                    &:hover {
                        border-color: $color-neutral-600;
                    }
                    .cld-image {
                        @include display-inline-flex();
                        @include align-items(center);
                        @include justify-content(center);
                        width: 100%;
                        height: 100%;
                        img {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }

            .postponed-note {
                background-image: img('task_postponed_bg_main_s30.svg');

                .postponed-info {
                    float: right;

                    width: calc(100% - 50px) !important;

                    .postponed-day {
                        @include display-inline-flex();
                        @include align-items();
                        @include font-size('s');
                        color: $color-primary-500;
                        font-family: $text-bold;
                        width: calc(100% - 30px);
                        min-height: 20px;
                    }

                    .postponed-reason {
                        @include display-inline-flex();
                        @include align-items();
                        @include font-size('s');
                        color: $color-black;
                        font-family: $text-bold;
                        width: calc(100% - 30px);
                        min-height: 20px;
                    }

                    .text {
                        @include font-size('s');
                        font-family: $text-medium;
                        color: $color-black;
                    }
                }
            }
            .alerts-container {
                @include background($size: 18px, $position: left 6px center);
                @include border-radius(3px);
                @include display-flex();
                @include align-items(center);
                @include justify-content(space-between);
                flex-direction: row;
                width: 100%;
                height: auto;
                padding: 5px;
                margin: 6px 9px;
                border: 1px solid $color-neutral-300;
                background-color: $color-white;
                gap: 2px;

                .alerts {
                    width: 100%;
                    &.has-incident {
                        width: 82%;
                    }

                    .alert-item,
                    .issue-item {
                        @include text-ellipsis();
                        @include font-size(xs);
                        color: $color-neutral-600;
                        font-family: $text;
                        width: 100%;
                        display: block;
                        white-space: wrap;
                    }

                    .alert-item {
                        padding-left: 24px;
                        display: flex;
                        align-items: center;
                        position: relative;

                        &.action-plan {
                            &.alert {
                                padding: 12px 0 12px 24px;
                            }
                        }

                        &::after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            width: 16px;
                            height: 16px;
                        }

                        &.action-plan {
                            &.info {
                                &::after {
                                    background-image: img('task_issue_bg_info_s10.svg');
                                    background-repeat: no-repeat;
                                    background-size: cover;
                                }
                            }

                            &.alert::after {
                                background-image: img('task_issue_bg_warning_s10.svg');
                                background-repeat: no-repeat;
                                background-size: cover;
                                width: 22px;
                                height: 22px;
                            }

                            &.error::after {
                                background-image: img('task_issue_bg_error_s10.svg');
                                background-repeat: no-repeat;
                                background-size: contain;
                            }
                        }
                        &.info::after {
                            background-image: img('task_warning_info_s10.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                        }

                        &.alert::after {
                            background-image: img('task_warning_warning_s10.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                        }

                        &.error::after {
                            background-image: img('task_warning_error_s10.svg');
                            background-repeat: no-repeat;
                            background-size: contain;
                        }
                    }

                    .issue-item {
                        margin: 9px 0 3px 0px;
                        font-family: $text;
                        font-style: italic;

                        b {
                            @include font-size(xs);
                            font-family: $text-bold;
                            font-style: italic;
                        }
                    }
                }
            }

            &[data-type="1"],   // CHECK

            &[data-type="17"] {
                .response {
                    height: 40px;

                    &.uncompleted {
                        height: auto;
                    }

                    .value {
                        overflow: auto;
                        @include border-radius(100%);
                        @include background($size: 30px, $color: $color-neutral-200);
                        border: 2px solid $color-neutral-200;
                        height: 30px !important;
                        width: 30px !important;
                        content: '';
                    }
                    .value.checked {
                        @include background($size: 30px, $image: img('checked_ok.svg'));
                        border: none;
                    }
                    .value.unchecked {
                        @include background($size: 30px, $image: img('error_alert.svg'));
                        border: none;
                    }
                }
            }

            &[data-type="2"],   // YES/NO
            &[data-type='3'] {
                .response {
                    @include justify-content();
                    @include font-size('m');
                    @include border-radius(40px);

                    line-height: 1.2;
                    text-align: center;
                    color: #777;
                    height: auto;

                    padding: 3px 6px 3px 3px;
                    margin: 3px 6px 3px 0px;

                    &[data-value='0'] {
                        color: $color-white;
                        background-color: $color-error-500;
                        font-weight: 700;
                        margin: 3px 0;
                        padding: 5px 15px;
                    }
                    &[data-value='1'] {
                        color: $color-white;
                        background-color: $color-success-500;
                        font-weight: 700;
                        margin: 3px 0;
                        padding: 5px 15px;
                    }
                    &[data-value='2'] {
                        font-weight: 700;
                        color: $color-white;
                        background-color: #999;
                        margin: 3px 0;
                        padding: 5px 15px;
                    }
                    &.na {
                        line-height: initial;
                        text-transform: none;
                        background-position: left 10% top 50%;
                        margin: 3px 0;
                        padding: 5px 15px;
                    }
                    &.out-schedule {
                        height: 16px !important;
                    }
                }
            }

            &[data-type='4'] {
                // TEXT
                .ans-top {
                    @media (max-width: 767px) {
                        display: flex;
                        flex-direction: column;

                        .statement {
                            width: 100%;

                            .titl {
                                width: 100%;
                            }
                        }

                        .response {
                            padding: 4px !important;
                            margin: 0px !important;
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;
                            background-color: $color-white;
                        }
                    }
                }

                .smaller-width {
                    @media (min-width: 768px) {
                        min-width: calc(100% - 300px);
                        max-width: calc(100% - 300px);
                    }
                }

                .response {
                    @include justify-content(flex-start);

                    @include font-size('sm');

                    background-color: $color-white;

                    margin: 0px 0px 0px 4px !important;

                    &.uncompleted {
                        background-color: unset !important;
                        margin: 6px !important;
                    }
                }
            }

            &[data-type='5'] {
                // IMAGE

                .statement {
                    width: 70%;
                    height: 100%;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }

                .response {
                    @include justify-content(flex-end);
                    @include background($size: cover);
                    @include border-radius(3px);

                    height: auto;
                    overflow: auto;
                    cursor: pointer;

                    @media (max-width: 400px) {
                        max-width: 208px;
                    }

                    @media (min-width: 401px) and (max-width: 767px) {
                        .answered {
                            min-width: 140px;
                            max-width: 80%;
                        }
                    }

                    .response {
                        width: 100%;
                        @include display-flex();
                        flex-wrap: wrap;
                        gap: 8px;
                    }

                    .image {
                        @include display-flex();
                        @include justify-content(center);
                        @include align-items(center);
                        flex-direction: column;
                        img {
                            width: 90px;
                            height: 90px;
                            @include border-radius(3px);
                            border: 1px solid $color-neutral-300;
                            object-fit: cover;
                        }
                    }

                    &.uncompleted {
                        border: none;
                    }

                    &.out-schedule {
                        width: 16px;
                        height: 22px;
                        background-size: 16px;
                    }
                }
            }

            &[data-type='6'] {
                &.ans-intime {
                    .response {
                        width: 120px;
                    }
                }
            }

            &[data-type='15'], // TEMPERATURE
            &[data-type='6'] {
                // NUMERIC / NUMÉRICO

                .response {
                    line-height: 1.5;
                    font-family: $text-medium;
                    display: block;

                    background-color: $color-white;
                    text-align: center;

                    .uncompleted {
                        width: 128px;
                        font-family: $text-light;
                    }
                }
            }

            &[data-type='7'] {
                // DATE
                .statement {
                    width: calc(100% - 150px);
                }
                .response {
                    @include justify-content();

                    background-color: $color-white;
                }
            }

            &[data-type='10'] {
                // TEXTAREA

                .ans-top {
                    @media (max-width: 767px) {
                        display: flex;
                        flex-direction: column;

                        .statement {
                            width: 100%;
                            min-width: 100%;
                            max-width: 100%;

                            .titl {
                                width: 100%;
                            }
                        }

                        .response {
                            padding: 4px !important;
                            margin: 0px !important;
                            width: 100%;
                            display: flex;
                            justify-content: flex-end;
                            background-color: $color-white;
                        }
                    }
                }

                .smaller-width {
                    @media (min-width: 766px) {
                        min-width: calc(100% - 400px);
                        max-width: calc(100% - 400px);
                    }
                }

                .response {
                    @include justify-content(flex-start);
                    padding-left: 10px;
                    margin: 0px !important;
                    @include font-size('sm');
                    background-color: $color-white;

                    &.uncompleted {
                        background-color: $color-white;
                        margin: 6px !important;
                    }
                }
            }

            &[data-type='11'] {
                // MULTIPLE SELECT
                .ans-top {
                    @media (max-width: 767px) {
                        .response {
                            margin: 0px !important;
                        }

                        .na {
                            margin: 4px 0px;
                        }
                    }
                }
                .statement {
                    width: 490px;
                    max-width: 100%;
                }
                &.ans-intime {
                    .response {
                        margin: 3px 0px 6px 6px;
                    }
                }
                .response {
                    grid-column-start: -1;
                    @include font-size('sm');
                    margin-left: 189px;
                    .selector-container {
                        height: auto;
                        max-height: max-content;
                        .selector-item {
                            height: auto;
                        }
                    }

                    &.warningRes {
                        margin: 3px 0px 6px 0px;
                        padding: 0;
                    }
                    &.uncompleted {
                        background-color: $color-white;
                        color: $color-neutral-500;
                    }
                }
            }

            &[data-type='12'] {
                // HOUR
                .statement {
                    width: calc(100% - 80px);
                }
                .response {
                    @include justify-content();
                    // background-color: $ans-value-bg;
                    background-color: $color-white;
                    width: 100px;
                    text-align: center;
                    margin-right: 5px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    padding: 4px 10px;
                }
            }

            &[data-type='13'] {
                // DATETIME
                .statement {
                    align-items: right;

                    @media (min-width: 768px) {
                        max-width: calc(100% - 175px);
                    }
                }
                .response {
                    @include justify-content();
                    background-color: $color-white;
                    margin-left: 0;

                    &.uncompleted {
                        width: 165px;
                    }
                }
            }

            &[data-type='14'] {
                // SIGNATURE
                .ans-top {
                    @media (max-width: 767px) {
                        display: block;
                    }
                }

                .statement {
                    @media (max-width: 767px) {
                        width: 100%;
                        max-width: 100%;
                    }
                }

                .smaller-width {
                    @media (min-width: 768px) {
                        max-width: calc(100% - 155px);
                    }
                }

                .response {
                    display: flex;

                    .answered {
                        flex-direction: column;
                    }

                    @media (max-width: 767px) {
                        width: 100%;
                    }

                    &.uncompleted {
                        height: auto;
                        width: 145px;
                    }

                    .image {
                        width: 150px;
                        display: block;
                    }

                    .employee {
                        @include font-size('sm');
                        @include text-ellipsis();
                        display: block;
                        padding-left: 4px;
                    }
                }
            }

            &[data-type='15'] {
                // TEMPERATURE
                .response {
                    @include justify-content();
                    text-align: center;
                }
            }

            &[data-type='16'] {
                // EMAIL
                border: 1px solid $color-neutral-200 !important;

                .uncompleted {
                    display: none;
                }

                .notDone .warningRes {
                    color: $color-neutral-500 !important;
                }

                .response {
                    width: 160px;
                }
            }

            &[data-type='18'] {
                // MESSAGE
                .statement {
                    width: 100%;
                }
                .response {
                    display: none;
                }
            }

            &.nsnr {
                @include display-flex();
                @include justify-content(flex-start);
                @include align-items();

                .response {
                    @include justify-content(flex-end);
                    @include text-ellipsis();
                    @include font-size('sm');
                    text-transform: unset;
                    background-color: $color-white;

                    text-align: right;
                    height: auto;
                    cursor: default;
                    color: $color-neutral-500;
                    font-style: italic;

                    margin: 0;

                    &::after {
                        content: '';
                    }
                }
            }

            &.postponed {
                .statement {
                    @include display-flex();
                    @include flex-wrap(wrap);
                    width: calc(100% - 150px) !important;
                }

                .response {
                    @include display-flex();
                    @include justify-content(flex-end);
                    width: 150px;
                    background-color: $color-white;
                    margin: 6px 0 !important;
                    padding: 0 6px !important;

                    .postponed {
                        @include align-items();
                        @include border-radius(4px);
                        @include text-ellipsis();
                        @include background($image: img('countdown_main_s40.svg'), $size: 15px, $position: left 8px top 10px);
                        @include font-size('m');
                        padding: 5px 9px 5px 30px;
                        text-align: center;
                        background-color: $color-primary-100;
                        font-family: $text-bold;
                        color: $color-primary-500;
                        text-transform: none;
                    }
                    &:after {
                        content: '';
                    }
                }
            }

            &.na {
                .statement {
                    @include display-flex();
                    @include flex-wrap(wrap);

                    @media (max-width: 767px) {
                        width: 100%;
                        max-width: 100%;
                    }

                    @media (min-width: 768px) {
                        width: calc(100% - 150px) !important;
                    }
                }

                .response {
                    @include display-flex();
                    @include justify-content(flex-end);

                    background-color: $color-white;
                    margin: 6px 0 !important;
                    padding: 0 6px !important;

                    @media (min-width: 768px) {
                        width: 150px;
                    }

                    .na {
                        @include align-items();
                        @include border-radius(4px);
                        @include text-ellipsis();
                        @include background($image: img('error_main_s40.svg'), $size: 15px, $position: left 10% top 50%);
                        @include font-size('m');
                        padding: 5px 9px 5px 30px;
                        text-align: center;
                        background-color: $color-secondary-100;
                        font-family: $text-medium;
                        color: $color-secondary-800;
                    }
                    &:after {
                        content: '';
                    }
                }
            }

            &.uncompleted:not(.ans-intime):not(.ans-future) {
                border-color: $color-error-500;
            }

            &.ans-intime,
            &.ans-future {
                border-color: $color-neutral-300;

                .response.uncompleted {
                    @include border-radius(4px);
                    @include background($size: 25px);
                    border: 2px dashed $color-neutral-300;
                    width: 50px;
                    height: 50px;
                    margin: 6px;
                }

                &[data-type='1'] .response.uncompleted,
                &[data-type='17'] .response.uncompleted {
                    // CHECK, YES/NO, DOCUMENT + CHECK
                    background-image: img('format_check_inactive.svg');
                }
                &[data-type='2'] .response.uncompleted,
                &[data-type='3'] .response.uncompleted {
                    // YES/NO/NC
                    background-image: img('format_multiple_inactive.svg');
                    background-size: 35px;
                }
                &[data-type='4'] .response.uncompleted,
                &[data-type='10'] .response.uncompleted {
                    // TEXT, TEXTAREA
                    background-image: img('format_text_inactive.svg');
                }
                &[data-type='5'] .response.uncompleted {
                    // FOTO
                    background-image: img('format_image_inactive.svg');
                }
                &[data-type='6'] .response.uncompleted {
                    // NUMERIC
                    background-image: img('format_number_inactive.svg');
                }
                &[data-type='7'] .response.uncompleted,
                &[data-type='12'] .response.uncompleted,
                &[data-type='13'] .response.uncompleted {
                    // DATE, TIME, DATETIME
                    background-image: img('format_datetime_inactive.svg');
                }
                &[data-type='11'] .response.uncompleted {
                    // MULTIPLE
                    background-image: img('format_select_inactive.svg');
                    background-size: 35px;
                }
                &[data-type='14'] .response.uncompleted {
                    // SIGNATURE
                    background-image: img('format_sign_inactive.svg');
                }
                &[data-type='15'] .response.uncompleted {
                    // TEMPERATURE
                    background-image: img('format_temperature_inactive.svg');
                }
                &[data-type='16'] .response.uncompleted,
                &[data-type='18'] .response.uncompleted {
                    // EMAIL, MESSAGE
                    background-image: img('format_mail_inactive.svg');
                }
                &[data-type='19'] .response.uncompleted {
                    background-image: img('format_link_inactive.svg');
                }
                &[data-type='20'] .response.uncompleted {
                    background-image: img('library_second_inactive.svg');
                }
            }
        }
        .content.extended {
            .statement {
                font-family: $text-light;
            }
            .response {
                background-color: $color-white;
                margin: 3px 6px 6px 6px;
                text-align: left;
            }
        }
        .content.child {
            width: $ans-width - 4%;
            margin-left: 4%;
        }

        .user-tag {
            width: fit-content;

            .avatar {
                width: 20px;
                height: 20px;
            }
        }
    }

    .answer-title,
    .answer-subtitle {
        text-align: left;
        border-bottom: 1px solid $color-neutral-200;

        span {
            display: inline-block;
            font-family: $text;
            padding: 3px 6px;
        }
    }
    .answer-title {
        width: 100%;
        margin: 40px auto 15px auto;
        margin-bottom: 20px;

        span {
            @include font-size(22px);
        }
    }
    .answer-subtitle {
        width: $ans-width;
        margin: 30px auto 15px auto;
        margin-bottom: 20px;

        span {
            @include font-size(18px);
        }
    }

    .section {
        @include display-flex();
        width: 100%;
        margin-top: 16px;
        margin-bottom: 15px;

        @media (min-width: 901px) {
            margin-top: 40px;
        }

        .section-title {
            font-size: 20px;
            font-family: $text-bold;
            width: calc(100% - 100px);
            color: $color-black;
        }

        .section-score {
            @include font-size('m');
            font-family: $text-bold;
            width: 100px;
            text-align: right;
        }
        .score-audit {
            padding: 2px 6px;
            width: fit-content;
        }
    }
    .ans-side {
        display: grid;
        justify-content: center;
        width: 45px;
        height: 45px;
        background-color: $color-success-500;
        border-radius: 4px;
        margin-left: 4px;

        .currenteScore,
        .totalScore {
            color: $color-white;
            margin-left: 10px;
        }
        .divideLine {
            border-bottom: 0.5px solid $color-white;
            width: 30px;
        }
        .currenteScore {
            font-family: $text-bold;
        }
        .totalScore {
            font-family: $text-light;
            margin-bottom: 4px;
        }
    }

    .error-background {
        background-color: $color-error-500;
    }
}

.report-container.opened {
    .header {
        background-image: img('up_dark.svg');
    }
}

.report.attachments,
.report.feedback {
    display: none;

    .attach,
    .feed {
        clear: both;
        margin-bottom: 1cm;
    }
    .report-subtitle {
        @include font-size(16px);
        color: #444;
        margin: 0;
        padding: 0;
        padding-left: 20px;
    }

    .title {
        @include background($position: left center, $size: 15px);
        @include font-size(16px);
        color: #444;
        margin: 0;
        padding: 0;
        padding-left: 18px;
    }
}
.report.attachments {
    .title {
        background-image: img('attach_dark.svg');
    }

    .statement {
        @include font-size(14px);
        color: #666;
    }

    .image {
        display: inline-block;
        max-height: 28cm;
        max-width: 100%;

        &.sign {
            width: 350px;
        }
    }
}
.report.feedback {
    .title {
        background-image: img('alert_dark.svg');
    }

    .message {
        @include font-size(16px);
        padding: 0px 6px 3px 6px;
        width: 100%;
        color: #888;

        b {
            @include font-size(16px);
            color: #888;
        }

        ul {
            list-style: disc;
            padding: 5px 0;
            padding-left: 30px;
        }

        li {
            @include font-size(16px);
            color: #888;
        }
    }
}

.full-width {
    width: 100% !important;
}

@media print {
    .report-container * {
        margin: 0 !important;
        padding: 0 !important;
    }

    .report {
        min-height: auto !important;
        height: 100% !important;

        .actionPlan-title {
            margin: 0px;
        }
        .section-header {
            margin-top: 0 !important;
            page-break-inside: avoid !important;
        }
        .report-header .report-data .report-title {
            @include font-size('ml');
        }

        .generated_date {
            @include font-size('xs');
            font-family: $text;
        }

        .answer .content {
            margin-top: 0px;
            .description {
                @include font-size('xs');
                font-family: $text-light;
            }
            .title {
                font-family: $text;
                @include font-size('xs');
            }
        }

        .section .section-title {
            @include font-size('sm');
            font-family: $text-medium;
        }
    }
}

@media (max-width: 900px) {
    .report {
        position: relative !important;
        width: 100% !important;
        display: block;
    }
}
</style>
