<template>
    <div id="content" class="supervise-audit" v-if="!reportView && canLoadList">
        <ViewConfigForm
            v-if="displayViewConfigForm"
            @view-created-or-edited="handleDisplayView"
            @on-view-deleted="setDefaultViewAsSelectedView"
            @on-cancel-button="closeViewConfigForm"
            :isEdit="configViewIsEdit"
            :selectedView="selectedView"
        />

        <div class="supervise-top-bar-actions">
            <div class="sidebar-filters-top-buttons">
                <Button bType="showOrHideFilters" @on-button-click="handleShowHideFiltersSidebar" />
                <!-- VIEWS DROPDOWN MENU AND EDIT VIEW BUTTON -->
                <template v-if="showViews && viewsList.length > 0">
                    <ViewsDropdownMenu
                        v-if="!isSmallScreen"
                        :views="viewsList"
                        :selectedView="selectedView"
                        @on-view-selected="handleDisplayView"
                        @on-create-view="handleCreateView"
                        :newFiltersAppliedToAView="newFiltersAppliedToAView"
                    />
                    <Button v-if="showEditViewButton && !isSmallScreen" bType="editIcon" customClass="edit-view-button" @on-button-click="handleEditView" />
                    <Button
                        v-if="newFiltersAppliedToAView && !isSmallScreen"
                        bType="createIcon"
                        customClass="edit-view-button"
                        @on-button-click="handleCreateView"
                    />
                </template>
            </div>
            <div class="supervise-table-actions-buttons">
                <Button v-if="selected.length > 0 && !isMobile" bType="file" :bCallback="goToReport" :bLabel="pluralize()" />

                <Button bType="more" :bCallback="showMoreOptions" />

                <div class="more-options" v-if="moreOptions">
                    <Button
                        bType="file"
                        :bCallback="downloadCsv"
                        :bLabel="$tc('supervise.checklists.table_headers.download_csv', selected.length == 1 ? 1 : 2)"
                    />
                    <Button
                        v-if="isDlRepor() && selected.length > 0"
                        bType="file"
                        :bCallback="downloadExcel"
                        :bLabel="$tc('supervise.checklists.table_headers.download_excel', selected.length == 1 ? 1 : 2)"
                    />
                    <Button v-if="showDownloadPdf" bType="print" :bCallback="downloadPDF" bLabel="nvgt.report.download_pdf" />
                </div>
            </div>
        </div>
        <div class="supervise-container" :class="{ 'with-trial': trialEndDate }">
            <!-- SIDEBAR FILTERS -->
            <div class="sidebar-filters" :class="{ 'empty-results': items.length == 0, 'hidden-filters-sidebar': filtersSidebarIsHidden }">
                <SuperviseAuditsFiltersBox
                    :key="filterKey"
                    :selectedView="selectedView"
                    :applyFilter="applyFilter"
                    :pagination="pagination"
                    :sortBy="sortBy"
                    :sortType="sortType"
                    :changePagination="changePagination"
                    ref="filterAudit"
                    @reset="showButtonReset"
                    @resetPageDatatable="top(true)"
                    @resetPagination="resetPagination"
                    @on-reset-filters="setDefaultViewAsSelectedView"
                    @filtersApplied="closeSidebarOnMobile"
                />
            </div>

            <!-- TABLE -->
            <div class="data-table" :class="{ 'empty-results': items.length == 0 }">
                <div v-if="items" @click="editColumns()" class="edit-columns"></div>
                <v-data-table
                    :update:sort-by="sortTable()"
                    v-if="items"
                    v-model="selected"
                    :headers="headers"
                    :items="items"
                    fixed-header
                    sort-by="created_date"
                    :sort-desc="true"
                    hide-default-footer
                    show-select
                    :page.sync="pagination.page"
                    :items-per-page="pagination.itemsPerPage"
                    @page-count="pageCount = $event"
                    :options.sync="pagination"
                >
                    <template #item.location_name="item">
                        <div class="location_name" @click="goToReport(item.item)">
                            <!-- v-if="datatableHeaders[0].location_name.visible" -->
                            <span>{{ item.item.location_name }}</span>
                        </div>
                    </template>
                    <template #item.issue_id="item">
                        <div class="id" @click="goToReport(item.item)">
                            <span class="id">#{{ item.item.issue_id }}</span>
                        </div>
                    </template>
                    <template #item.title="item">
                        <div class="title" @click="goToReport(item.item)">
                            <span>{{ item.item.title }}</span>
                            <span v-if="!templateIsActive(item.item.template_id)" class="inactive-card"> {{ $t('template.inactive') }}</span>
                        </div>
                    </template>
                    <template #item.status="item">
                        <!--  <div class="status" @click="goToReport(item.item)">
                            <span class="estate" :class="getColorState(item.item.status)">{{ getState(item.item.status) }}</span>
                        </div> -->
                        <States :currentState="item.item.status" :auditId="item.item.id"></States>
                    </template>
                    <template #item.created_date="item">
                        <div class="date" @click="goToReport(item.item)">
                            <span class="date-hour" v-html="spanishDateFormat(item.item.created_date)"></span>
                        </div>
                    </template>
                    <template #item.employee_ref="item">
                        <div class="created-by" @click="goToReport(item.item)" v-if="getEmployee(item.item.employee_ref)">
                            <span
                                class="employee-img"
                                :style="{
                                    backgroundImage: 'url(' + getEmployee(item.item.employee_ref).avatar + ')'
                                }"
                            ></span>
                            <span class="employee-name">{{
                                getEmployee(item.item.employee_ref).name + ' ' + getEmployee(item.item.employee_ref).surname
                            }}</span>
                        </div>
                        <div class="no-employee" v-else>-</div>
                    </template>
                    <!-- PUNTUACION / SCORE-->
                    <template #item.score="item">
                        <!-- <template v-if="item.item.total == 0">
                            <div class="na">{{ $t('nvgt.report.answer_na') }}</div>
                        </template>
            <template v-else> -->
                        <div class="score" @click="goToReport(item.item)">
                            <!-- <span class="score-done">{{ item.item.completed }}/{{ item.item.total }}</span> -->
                            <span v-if="item.item.finalScore" :class="{ 'with-color': item.item.finalScore.color }" class="score-done">
                                <div v-if="item.item.finalScore.color" :style="{ backgroundColor: item.item.finalScore.color }" class="score-color"></div>
                                <span v-if="item.item.score > 0" class="score-name">{{ item.item.finalScore.name }} ({{ item.item.score }}%)</span>
                                <span v-else class="score-name">{{ item.item.finalScore.name }}</span>
                            </span>
                            <span class="score-done" v-else-if="item.item.score">{{ item.item.score }}%</span>
                            <!-- <span v-else class="score-done">{{ item.item.score }}%</span> -->
                            <span v-else class="score-done"> - </span>

                            <!-- <span class="score-percent">({{ item.item.score }}%)</span> -->
                        </div>
                        <!-- </template> -->
                    </template>
                    <template #item.planActionDisplay="item">
                        <div class="planAction" @click="goToReport(item.item)">
                            <span
                                v-if="item.item.data && item.item.data.actionplans_quantity && item.item.data.actionplans_quantity > 0"
                                class="estate"
                                :class="getColorPlanAction(item.item.data.actionplans_score)"
                            >
                                {{ formatPlanActionStatus(item.item.data.actionplans_score) }}
                            </span>
                            <span v-else>-</span>
                        </div>
                    </template>
                    <template #item.resume="item">
                        <div class="resume" @click="goToReport(item.item)">
                            <div class="summary">
                                <div v-if="!summaryIs0(item.item.resume.it)" class="summary-uncompleted-task" v-tooltip="$t('supervise.checklists.summary_3')">
                                    {{ item.item.resume.it }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_3')"
                                    src="~@/../public/img/task_uncompleted_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.a)" class="summary-alerts" v-tooltip="$t('supervise.checklists.summary_2')">
                                    {{ item.item.resume.a }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_2')"
                                    src="~@/../public/img/task_warning_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.is)" class="summary-incidents" v-tooltip="$t('supervise.checklists.summary_1')">
                                    {{ item.item.resume.is }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_1')"
                                    src="~@/../public/img/task_issue_inactive.svg"
                                />

                                <div v-if="!summaryIs0(item.item.resume.n)" class="summary-note" v-tooltip="$t('supervise.checklists.summary_4')">
                                    {{ item.item.resume.n }}
                                </div>
                                <img
                                    v-else
                                    class="no-summary"
                                    style="background-size: 12px"
                                    v-tooltip="$t('supervise.checklists.summary_4')"
                                    src="~@/../public/img/task_note_inactive.svg"
                                />
                            </div>
                        </div>
                    </template>

                    <template v-slot:no-data>
                        <div class="andy-datatable-empty" v-if="itemsLoaded">
                            <EmptyTable
                                buttonClass="btn-action"
                                @buttonAction="resetFilter"
                                buttonType="clearFilters"
                                :buttonText="showReset ? $t('assets.filter.delete') : undefined"
                                :title="$t('empty_table.users_title_supervise')"
                                type="supervise"
                                :description="$t('empty_table.issues_empty_filter')"
                            ></EmptyTable>
                        </div>
                    </template>
                </v-data-table>
                <div id="pagination" v-if="!(numItems == 0)">
                    <div class="totalItems">
                        <span class="total">
                            <strong class="total-results">{{ numItems }}</strong>
                            {{ $t('supervise.checklists.table_headers.results') }}
                        </span>
                    </div>
                    <v-pagination v-model="pagination.page" @input="changePage" color="#2c52c4" :length="pages" :total-visible="maxPageVisibles"></v-pagination>
                    <div class="rows-page">
                        <v-select @change="changedItemPerPage = true" :items="rowsPerPage" :label="nRegistros" v-model="pagination.itemsPerPage"></v-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ReportAudit :reports="aReportsID" @back="closeReport()" v-else-if="selected.length > 0"></ReportAudit>
</template>

<script>
import SuperviseAuditsFiltersBox from '@/components/domain/audit/supervise/SuperviseAuditsFiltersBox.vue'
import ReportAudit from '@/components/domain/audit/supervise/report'
import EmptyTable from '@/components/ui/EmptyTable'
import ViewsDropdownMenu from '@/components/domain/filtersViews/ViewsDropdownMenu.vue'
import ViewConfigForm from '@/components/domain/filtersViews/ViewConfigForm.vue'
import { TYPE } from '@/constants'
import States from '@/components/domain/audit/states.vue'

export default {
    components: {
        SuperviseAuditsFiltersBox,
        ReportAudit,
        EmptyTable,
        ViewsDropdownMenu,
        ViewConfigForm,
        States
    },
    name: 'SuperviseAudit',
    data() {
        return {
            type: TYPE.AUDIT,
            applyFilter: false,
            showReset: false,
            noChanges: false,
            // VIEW VARIABLES
            reportView: false,
            aReportsID: [],

            // DATATABLES
            values: false,
            singleSelect: false,
            selected: [],
            pagination: {
                page: 1,
                itemsPerPage: 20
            },
            rowsPerPage: [10, 20, 50],
            pageCount: 0,
            maxPageVisibles: 10,
            options: {},
            filterKey: 1,
            sortBy: false,
            sortType: false,
            changePagination: false,
            changedItemPerPage: false,
            filtersSidebarIsHidden: false,
            moreOptions: false,

            // Filters VIEWS
            selectedView: null,
            displayViewConfigForm: false,
            configViewIsEdit: false,
            headersConf: localStorage.getItem(`headersConf_cp_audits`) ? JSON.parse(localStorage.getItem(`headersConf_cp_audits`)) : [],
            datatableHeaders: [
                {
                    text: this.$t('supervise.checklists.table_headers.localization'),
                    value: 'location_name',
                    align: 'start',
                    sortable: true,
                    class: 'header-table',
                    visible: true
                },
                {
                    text: this.$t('supervise.audits.table_headers.audit'),
                    align: 'start',
                    sortable: true,
                    value: 'title',
                    class: 'header-table',
                    visible: true
                },
                {
                    text: this.$t('supervise.issues.table_headers.state'),
                    value: 'status',
                    align: 'start',
                    sortable: true,
                    class: 'header-table',
                    visible: true
                },
                {
                    text: this.$t('supervise.checklists.table_headers.date'),
                    value: 'created_date',
                    align: 'start',
                    sortable: true,
                    sort: this.sortColumnDate,
                    class: 'header-table',
                    visible: true
                },
                {
                    text: this.$t('supervise.audits.table_headers.supervised_by'),
                    value: 'employee_ref',
                    align: 'start',
                    sortable: true,
                    sort: this.sortColumnScore,
                    class: 'header-table',
                    visible: true
                },
                {
                    text: this.$t('supervise.audits.table_headers.finalNote'),
                    value: 'score',
                    align: 'start',
                    sortable: true,
                    class: 'header-table',
                    visible: true
                },
                {
                    text: this.$t('supervise.checklists.table_headers.planAction'),
                    value: 'planActionDisplay',
                    align: 'start',
                    sortable: true,
                    class: 'header-table',
                    visible: true
                },
                {
                    text: this.$t('supervise.checklists.table_headers.summary'),
                    value: 'resume',
                    align: 'start',
                    sortable: true,
                    class: 'header-table',
                    visible: true
                }
            ],

            // for mobile
            windowWidth: window.innerWidth
        }
    },
    computed: {
        headers: {
            get() {
                let headers = this.datatableHeaders

                if (this.headersConf.length > 0) {
                    if (this.headersConf.length !== headers.length) {
                        log('longitude', this.headersConf, headers)
                        //Si tiene distinto tamaño tiene que ver si tiene que añadir o quitar, y respetar los visibles
                        let newHeaders = []
                        for (let i = 0; i < headers.length; i++) {
                            let header = headers[i]
                            let headerConf = this.headersConf.find((h) => h.value === header.value)
                            if (headerConf) {
                                header.visible = headerConf.visible
                                newHeaders.push(header)
                            } else {
                                newHeaders.push(header)
                            }
                        }

                        headers = newHeaders
                        return headers
                    }
                } else {
                    this.headersConf = headers
                }
                return this.headersConf
            },
            set(value) {
                this.headersConf = value
            }
        },
        tableHeaders() {
            this.datatableHeaders = [...this.headers]
            return this.datatableHeaders.filter((header) => header.visible)
        },
        nRegistros() {
            var letter = this.$t('supervise.checklists.table_headers.nRegisters')
            var nRegisters = this.pagination.itemsPerPage
            letter = letter.toString().replace(' ', ' ' + nRegisters + ' ')
            return letter
        },
        itemsLoaded() {
            return this.$store.getters['audits/getItemsLoaded']
        },
        pages() {
            const numItems = this.$store.getters['audits/getNumItems']
            return numItems ? Math.ceil(numItems / this.pagination.itemsPerPage) : 1
        },
        numItems() {
            return this.$store.getters['audits/getNumItems']
        },
        items() {
            var result = this.$store.getters['audits/getList'](1)
            return Object.values(result)
        },
        canLoadList() {
            return this.$store.getters['audits/getCanLoadList']
        },
        trialEndDate() {
            return this.$store.getters['login/getTrialEndDate']
        },

        // Filters VIEWS
        viewsList() {
            return this.$store.getters['filtersViews/getSuperviseAuditsViewsList']
        },

        defaultView() {
            return this.$store.getters['filtersViews/getSuperviseAuditsDefaultView']
        },

        selectedViewFilters() {
            if (this.selectedView) {
                return this.selectedView.data
            }
        },

        // (Feature flag)
        showViews() {
            return this.$store.getters['login/getFilterViewsAvailable']
        },

        showEditViewButton() {
            const userRolLevel = this.$store.getters['getLevelUser']

            if (!this.selectedView) return false

            const viewType = this.selectedView.type

            // if is Standard View (type 1) never show the edit button
            if (viewType === 1) {
                return false
            }

            // if is Custom "Only for me" view (type 3) always show the edit button
            if (viewType === 3) {
                return true
            }

            // if is Custom "For the entire brand" (type 2) show edit button only to high level rol users:
            if (viewType === 2) {
                if (userRolLevel >= 7) {
                    return true
                } else return false
            }
        },

        newFiltersAppliedToAView() {
            const activeFilters = JSON.stringify(this.$store.getters['audits/getFiltersActive'])

            if (this.selectedView) {
                const selectedViewFilters = JSON.stringify(this.selectedView.data)

                return activeFilters !== selectedViewFilters
            }
        },
        showDownloadPdf() {
            const reportPdf = parseInt(this.$store.getters['account/getReportPdf'])
            if (reportPdf == 1) {
                return true
            }
            return false
        },
        isMobile() {
            return this.windowWidth <= 767
        },
        isSmallScreen() {
            // includes mobile and tablets
            return this.windowWidth <= 900
        }
    },
    methods: {
        formatPlanActionStatus(percentage) {
            if (percentage === 0) {
                return this.$t('nvgt.t1.status.notStart')
            } else if (percentage < 100) {
                return this.$t('nvgt.t1.status.current') + ` (${percentage}%)`
            } else if (percentage == 100) {
                return this.$t('nvgt.t1.status.past') + ' (100%)'
            } else {
                return '-'
            }
        },
        showMoreOptions() {
            this.moreOptions = !this.moreOptions
        },
        editColumns() {
            const self = this

            this.$popup.tableColumn({
                textSave: this.$t('button.save'),
                title: this.$t('supervise.custom_columns_popup.title'),
                headers: this.headers,
                routePop: this.$router.currentRoute.params.id,
                callSave: (obj) => {
                    self.headersConf = obj.$data.headers

                    localStorage.setItem(`headersConf_cp_audits`, JSON.stringify(obj.$data.headers))
                    self.sortTable()
                    self.$popup.close()
                    self.$forceUpdate()
                },
                callCancel: () => {
                    ;(self.headers = localStorage.getItem(`headersConf_cp_audits`)
                        ? JSON.parse(localStorage.getItem(`headersConf_cp_audits`))
                        : JSON.parse(localStorage.getItem(`initHeaders_cp_audits`))),
                        self.$popup.close()
                }
            })
        },
        sortTable() {
            this.sortBy = this.pagination.sortBy && this.pagination.sortBy.length > 0 ? this.pagination.sortBy : false
            this.sortType =
                this.pagination.sortBy && this.pagination.sortBy.length > 0
                    ? this.pagination.sortDesc.length > 0 && this.pagination.sortDesc[0]
                        ? 'desc'
                        : 'asc'
                    : false
        },

        resetPagination() {
            this.selected = []
            this.changePagination = false
            this.pagination.page = 1
            this.top(true)
        },
        templateIsActive(templateID) {
            var template = this.$store.getters['audits/getFilterTemplate'](templateID)

            if (template && template.status == '-2') {
                return false
            }
            return true
        },

        resetFilter() {
            this.$refs.filterAudit.resetFilters()
            this.showReset = false
        },
        showButtonReset(reset) {
            this.showReset = reset
        },
        top(cond) {
            // if (cond && !this.$route.params.noChangePageDatatable) {
            //     this.pagination.page = 1
            // }
            var container = document.querySelector('.v-data-table__wrapper')
            container.scrollTo(0, 0)
        },
        summaryIs0(summary) {
            if (summary == 0) {
                return true
            }
            return false
        },
        pluralize() {
            if (this.selected.length > 1) {
                return 'supervise.checklists.table_headers.show_informs'
            } else {
                return 'supervise.checklists.table_headers.show_inform'
            }
        },
        goToReport(item) {
            const amplitudeProps = { type: 'Audit' }
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Use of reports', amplitudeProps)

            this.$overlay.show()
            if (item) {
                this.selected.push(item)
            }
            var reports = [...this.selected]

            this.aReportsID = []
            for (var index in reports) {
                reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
            }

            this.reportView = true
        },
        isPosibleToDownload() {
            const maxItems = 1000
            if (this.numItems > maxItems) {
                this.$popup.alertNewDesing({
                    title: this.$t('supervise.checklists.error_download_excel'),
                    message: this.$t('supervise.checklists.error_download_excel_rows', { maxItems: maxItems })
                })
                return false
            }

            return true
        },
        isDlRepor() {
            const config = this.$store.getters['login/getConfig']
            return config && config.dlReport === 'true'
        },
        downloadCsv() {
            if (this.items.length == 0) {
                this.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_empty.title'),
                    message: this.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        this.moreOptions = false
                        this.$popup.close()
                    }
                })
                return
            }

            const amplitudeProps = {}
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Download reports', {
                ...amplitudeProps,
                reports: this.selected.length
            })

            this.$overlay.show()

            const filters = this.$store.getters['audits/getFiltersActive']

            if (this.selected.length > 0) {
                // Con ids
                this.aReportsID = []
                let reports = [...this.selected]
                let self = this

                for (let index in reports) {
                    reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
                }

                this.$store
                    .dispatch('audits/downloadReportListCsv', {
                        ids: this.aReportsID
                    })
                    .then((file) => {
                        let filename = file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.href = file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                if (this.isPosibleToDownload()) {
                    let self = this
                    const location = filters.location ? Object.values(filters.location).map(({ id }) => id) : ''
                    const template = filters.template ? Object.values(filters.template).map(({ id }) => id) : ''
                    const employees = filters.employees ? Object.values(filters.employees).map(({ id }) => id) : ''
                    this.$store
                        .dispatch('audits/downloadReportListCsv', {
                            location: location,
                            template: template,
                            employees: employees,
                            start_date: filters.type_date !== 'custom' ? filters.type_date : filters.start_date,
                            end_date: filters.end_date,
                            score: filters.score,
                            pagenum: this.pagination.page,
                            number_items: this.pagination.itemsPerPage,
                            sortBy: this.sortBy,
                            sortType: this.sortType
                        })
                        .then((file) => {
                            let filename = file.split('/').pop()
                            let link = document.createElement('a')
                            link.download = filename
                            link.href = file
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            self.$overlay.hide()
                        })
                        .catch((error) => {
                            console.error(error)
                            self.$overlay.hide()
                        })
                }
                this.$overlay.hide()
            }
        },
        downloadExcel() {
            const amplitudeProps = {}
            const employee = this.$store.getters['login/getLocalEmployee']
            if (employee) {
                amplitudeProps.employee = {
                    fullname: `${employee.name} ${employee.surname}`,
                    account_name: employee.account_name,
                    employee_email: employee.email
                }
            }
            this.$amplitude.track('Download reports', {
                ...amplitudeProps,
                reports: this.selected.length
            })

            this.$overlay.show()

            const filters = this.$store.getters['audits/getFiltersActive']

            if (this.selected.length > 0) {
                // Con ids
                this.aReportsID = []
                let reports = [...this.selected]
                let self = this

                for (let index in reports) {
                    reports[index].id != null ? this.aReportsID.push(reports[index].id) : true
                }

                this.$store
                    .dispatch('audits/downloadReportListExcel', {
                        ids: this.aReportsID
                    })
                    .then((file) => {
                        let filename = file.split('/').pop()
                        let link = document.createElement('a')
                        link.download = filename
                        link.href = file
                        document.body.appendChild(link)
                        link.click()
                        document.body.removeChild(link)
                        self.$overlay.hide()
                    })
                    .catch((error) => {
                        console.error(error)
                        self.$overlay.hide()
                    })
            } else {
                if (this.isPosibleToDownload()) {
                    let self = this
                    const location = filters.location ? Object.values(filters.location).map(({ id }) => id) : ''
                    const template = filters.template ? Object.values(filters.template).map(({ id }) => id) : ''
                    const employees = filters.employees ? Object.values(filters.employees).map(({ id }) => id) : ''
                    this.$store
                        .dispatch('audits/downloadReportListExcel', {
                            location: location,
                            template: template,
                            employees: employees,
                            start_date: filters.type_date !== 'custom' ? filters.type_date : filters.start_date,
                            end_date: filters.end_date,
                            score: filters.score,
                            pagenum: this.pagination.page,
                            number_items: this.pagination.itemsPerPage,
                            sortBy: this.sortBy,
                            sortType: this.sortType
                        })
                        .then((file) => {
                            let filename = file.split('/').pop()
                            let link = document.createElement('a')
                            link.download = filename
                            link.href = file
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                            self.$overlay.hide()
                        })
                        .catch((error) => {
                            console.error(error)
                            self.$overlay.hide()
                        })
                }
                this.$overlay.hide()
            }
        },
        closeReport() {
            this.selected = []
            this.reportView = false
            this.$router.push({
                name: 'SuperviseAudit',
                params: {
                    noChangePageDatatable: true
                }
            })
            this.$overlay.hide()
        },
        getHour(date) {
            var localLocale = moment(date)
            // moment.locale('es')
            localLocale.locale(false)
            return localLocale.format('HH:mm')
        },
        spanishDateFormat(valDate) {
            var localLocale = moment(valDate)
            // moment.locale('es')
            localLocale.locale(false)
            return localLocale.format('ddd DD MMM Y ')
        },

        load() {
            var self = this
            self.$overlay.show()

            if (this.$route.params.id) {
                self.$store.commit('audits/canLoadList', false)
                // self.$store.dispatch('audits/loadFilters', {}).then(function () {
                //     self.goToReport({ id: self.$route.params.id })
                //     self.$overlay.hide()
                // })

                self.goToReport({ id: self.$route.params.id })
                self.$overlay.hide()
            } else {
                self.$store.commit('audits/canLoadList', true)
                this.reportView = false
                this.selected = []
                var filterLocations = self.$store.getters['filterLocations/getFilterLocation']()
                var filterTemplates = self.$store.getters['audits/getFilterTemplate']()
                var filterStates = self.$store.getters['audits/getFilterStates']()

                if (!this.$route.params.noChangePageDatatable) {
                    if (Object.keys(filterLocations).length > 0 && Object.keys(filterTemplates).length > 0 && Object.keys(filterStates).length > 0) {
                        self.$store.dispatch('audits/loadFilters', {}).then(function () {
                            self.applyFilter = true

                            self.loadViews()

                            self.$overlay.hide()
                        })
                    } else {
                        self.$store.dispatch('audits/loadFilters', {}).then(function () {
                            self.loadViews()
                        })
                    }
                } else {
                    if (this.items.length <= 0) {
                        self.$store.dispatch('audits/loadFilters', {}).then(function () {
                            self.applyFilter = true

                            self.loadViews()

                            self.$overlay.hide()
                        })
                    }
                    self.$overlay.hide()
                }
            }

            this.$route.params.noChangePageDatatable = false
            if (!localStorage.getItem('initHeaders_cp_audits')) {
                localStorage.setItem('initHeaders_cp_audits', JSON.stringify(this.datatableHeaders))
            }
        },

        getState(id) {
            var status = this.$store.getters['audits/getFilterStates'](id)
            var result = status
            if (id === 0) {
                result = ''
            }
            return result.name
        },
        getEmployee(id) {
            if (!id) {
                return false
            }
            var employee = this.$store.getters['employee/getAccountEmployee'](id)
            return employee
        },
        formatTime(value) {
            return moment(value).format('DD/MM/YYYY HH:mm') != moment('00/00/0000 00:00').format('DD/MM/YYYY HH:mm')
                ? moment(value).format('DD/MM/YYYY HH:mm')
                : '-'
        },
        getColorState(state) {
            var result = ''
            if (state == 1) {
                result = 'finalizada'
            }
            if (state == 2) {
                result = 'en_progreso'
            }
            if (state == 3) {
                result = 'programada'
            }
            return result
        },
        getColorPlanAction(percentage) {
            if (percentage === 0) {
                return 'sin_empieza'
            } else if (percentage < 100) {
                return 'en_progreso'
            } else if (percentage === 100) {
                return 'finalizada'
            } else {
                return '-'
            }
        },
        sortColumnCreatedBy(a, b) {
            var employeeA = a
            var employeeB = b
            if (!a == 0) {
                var getemployeeA = this.$store.getters['audits/getFilterStates'](a)
                employeeA = getemployeeA.name
            }
            if (!b == 0) {
                var getemployeeB = this.$store.getters['tasks/getFilterStates'](b)
                employeeB = getemployeeB.name
            }

            if (employeeA < employeeB) {
                return -1
            }
            return 1
        },
        sortColumnDate(a, b) {
            if (a < b) {
                return -1
            }
            return 1
        },
        changePage() {
            this.top(true)
            this.changePagination = true
        },

        handleShowHideFiltersSidebar() {
            this.filtersSidebarIsHidden = !this.filtersSidebarIsHidden
            localStorage.setItem('SuperviseAuditSidebarIsHiddenValue', JSON.stringify(this.filtersSidebarIsHidden))
        },

        // Filters VIEWS METHODS
        loadViews() {
            this.$store.dispatch('filtersViews/loadSuperviseAuditsViews')
        },

        async handleDisplayView(view) {
            this.$overlay.show()

            const viewsAreUpdated = await this.$store.dispatch('filtersViews/loadSuperviseAuditsViews')

            if (viewsAreUpdated) {
                const selectedView = await this.$store.getters['filtersViews/getSuperviseAuditsViewById'](view.id)

                this.selectedView = selectedView
                this.$store.commit('filtersViews/setSuperviseAuditsSelectedView', this.selectedView)

                this.$overlay.close()
            }
        },

        handleCreateView() {
            this.configViewIsEdit = false
            this.displayViewConfigForm = true
        },

        handleEditView() {
            this.configViewIsEdit = true
            this.displayViewConfigForm = true
        },

        closeViewConfigForm() {
            this.displayViewConfigForm = false
            this.configViewIsEdit = false
        },

        setDefaultViewAsSelectedView() {
            this.selectedView = structuredClone(this.defaultView)

            this.$store.commit('filtersViews/setSuperviseAuditsSelectedView', this.selectedView)
        },
        showExportByEmailPopup() {
            const self = this

            const user = self.$store.getters['loginUser/getLocalUser']
            const email = user.email

            const messageWithEmail = self.$t('supervise.reports.export.popup_email.message', { email: `<b>${email}</b>` })
            const messageWithoutEmail = self.$t('supervise.reports.export.popup_email.message2')

            self.$popup.messageWithButton({
                title: self.$t('supervise.reports.export.popup_email.title'),
                message: email ? messageWithEmail : messageWithoutEmail,
                callCancel: () => {
                    self.$popup.close()
                }
            })
        },
        getReportsIdsList(listOfReportsObjs) {
            if (!listOfReportsObjs || listOfReportsObjs.length === 0) {
                return null
            }

            const ids = listOfReportsObjs.map((report) => {
                if (report.hasOwnProperty('id')) {
                    return report.id
                }
            })

            return ids
        },
        handleExportReport() {
            const self = this

            // let reportsQuantity
            if (self.selected.length > 0) {
                const idsList = self.getReportsIdsList(self.selected)
                self.$store.commit('reports/setReportsToExportIds', idsList)
                // reportsQuantity = parseInt(idsList.length)
            } else {
                self.$store.commit('reports/setReportsToExportIds', null)
                // reportsQuantity = parseInt(self.numItems)
            }

            self.$popup.close()

            // display loader or loader popup
            // if (reportsQuantity >= 10) {
            //     self.$popup.exportLoading()
            // } else self.$overlay.loading()

            // display loader:
            self.$overlay.loading()

            self.$store.dispatch('reports/exportReports', { type: TYPE.AUDIT }).then((response) => {
                if (!response.status) {
                    // close popup or loader
                    // if (reportsQuantity >= 10) {
                    //     self.$popup.close()
                    // } else self.$overlay.hide()

                    // close loader
                    self.$overlay.hide()

                    // if is imposible to export, ask user to change de filters:
                    if (response.error.code == -2) {
                        self.$popup.messageWithButton({
                            title: this.$t('supervise.reports.export.popup_avoid.title'),
                            message: this.$t('supervise.reports.export.popup_avoid.message2'),
                            customClass: 'error',
                            callCancel: () => {
                                // close "more options" dropdown menu
                                self.moreOptions = false
                                // close popup
                                self.$popup.close()
                            }
                        })
                    } else {
                        // if error code is not -2, show the modal that says that pdfs will be sent by email:
                        self.showExportByEmailPopup()

                        // close "more options" dropdown menu
                        self.moreOptions = false
                    }

                    return
                } else {
                    // close popup or loader
                    // if (reportsQuantity >= 10) {
                    //     self.$popup.close()
                    // } else self.$overlay.hide()

                    // close loader
                    self.$overlay.hide()

                    // open PDFs in a new tab
                    window.open(response.url, '_blank')

                    // close "more options" dropdown menu
                    self.moreOptions = false
                }
            })
        },
        downloadPDF() {
            const self = this

            if (self.items.length == 0) {
                self.$popup.messageWithButton({
                    title: self.$t('supervise.reports.export.popup_empty.title'),
                    message: self.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
                return
            }

            let reportsQuantity
            if (self.selected.length > 0) {
                reportsQuantity = self.selected.length
            } else {
                reportsQuantity = parseInt(self.numItems)
            }

            const maxQuantAllowed = 10000

            // modal to inform that quantity is not allowed and suggest applying more filters
            if (reportsQuantity > maxQuantAllowed) {
                self.$popup.close()

                self.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_avoid.title'),
                    message: this.$t('supervise.reports.export.popup_avoid.message', { maxQuantAllowed: `<b>${maxQuantAllowed}</b>` }),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
            } else if (reportsQuantity < 1 && reportsQuantity < maxQuantAllowed) {
                self.$popup.messageWithButton({
                    title: this.$t('supervise.reports.export.popup_empty.title'),
                    message: this.$t('supervise.reports.export.popup_empty.message'),
                    customClass: 'error',
                    callCancel: () => {
                        self.moreOptions = false
                        self.$popup.close()
                    }
                })
            } else {
                // PDF display options modal
                self.$popup.PDFDisplayOptions({
                    callSave: this.handleExportReport, // calls de store
                    callCancel: () => {
                        self.moreOptions = false
                    },
                    config: {
                        quantity: reportsQuantity,
                        tool: 'audit'
                    }
                })
            }
        },

        setInitialFiltersSidebarIsHiddenValue() {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseAuditSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        },

        handleResize() {
            this.windowWidth = window.innerWidth
        },

        closeSidebarOnMobile() {
            if (this.isMobile) {
                this.filtersSidebarIsHidden = true
            }
        }
    },
    watch: {
        $route: 'load',
        options: {
            handler() {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options
                this.sortBy = typeof sortBy[0] !== 'undefined' ? sortBy[0] : false
                this.sortType = typeof sortDesc[0] !== 'undefined' ? (sortDesc[0] ? 'desc' : 'asc') : false
                this.filterKey++
            },
            deep: true
        },
        'pagination.itemsPerPage': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterAudit.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },
        'pagination.page': {
            handler: function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.$refs.filterAudit.handleChangePagination(this.changedItemPerPage ? 1 : newVal.page)
                    this.changedItemPerPage = false
                }
            },
            deep: true
        },

        defaultView(defaultView) {
            if (!this.selectedView && this.items.length === 0 && defaultView) {
                this.setDefaultViewAsSelectedView()
            }
        },

        isSmallScreen(newVal, oldVal) {
            if (this.isSmallScreen) {
                this.filtersSidebarIsHidden = true
            } else {
                const storedValue = localStorage.getItem('SuperviseAuditSidebarIsHiddenValue')
                this.filtersSidebarIsHidden = storedValue !== null ? JSON.parse(storedValue) : false
            }
        }
    },
    created() {
        this.load()

        this.selectedView = this.$store.getters['filtersViews/getSuperviseAuditsSelectedView']

        window.addEventListener('resize', this.handleResize)
    },
    mounted() {
        if (this.$mq == 'portrait') {
            // By default, hide filters in portrait
            localStorage.setItem('SuperviseAuditSidebarIsHiddenValue', JSON.stringify(true))
        }

        this.setInitialFiltersSidebarIsHiddenValue()
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.supervise-audit {
    .more-options {
        position: absolute;
        top: 42px;
        right: 4px;
        padding: 3px 3px 3px 3px;
        z-index: 45;
        background-color: $color-neutral-200;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.3);
        border-radius: 4px;

        @media (min-width: 768px) {
            top: 54px;
            right: 16px;
        }

        button {
            display: block;
            // min-width: 153px;
            margin: 0px;
            margin-bottom: 4px !important;

            &:last-child {
                margin-bottom: 0px !important;
            }
        }
    }

    .supervise-container {
        display: flex;
        align-items: stretch;
        gap: 0.5em;

        .data-table {
            &.empty-results {
                pointer-events: visible !important;
            }

            .edit-columns {
                border: 1.5px solid $color-neutral-300;
                border-radius: 4px;
                background-image: img('edit_columns.svg');
                background-color: $color-white;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 16px;
                position: absolute;
                top: 16px;
                right: 8px;
                z-index: 44;
                width: 40px;
                height: 40px;

                &:hover {
                    cursor: pointer;
                }
            }

            .v-data-table,
            .v-data-table table {
                td > div > span {
                    @include font-size(sm);
                }

                .location_name span {
                    font-family: $text-bold !important;
                }

                .date {
                    display: flex;
                    flex-direction: column;

                    .hour {
                        @include font-size($size: sm);
                        font-family: $text-bold;
                    }
                }

                .status,
                .planAction {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: start;
                    align-items: center;

                    .estate {
                        @include background($color: $color-neutral-200);
                        height: 2rem;
                        width: 6rem;
                        text-align: center;
                        border-radius: 5px;
                        padding-top: 6px;
                        font-family: $text-bold;
                    }

                    .sin_empieza .en_progreso,
                    .finalizada,
                    .programada {
                        @include font-size(xs);
                        width: auto;
                        padding: 4px 8px;
                        height: auto;
                    }
                    .sin_empieza {
                        @include background($color: $color-neutral-500);
                        color: $color-white;
                    }
                    .en_progreso {
                        @include background($color: $color-warning-500);
                        color: $color-white;
                    }

                    .finalizada {
                        @include background($color: $color-primary-500);
                        color: $color-white;
                    }

                    .programada {
                        @include background($color: $color-info-500);
                        color: $color-white;
                    }
                }

                .planAction {
                    .estate {
                        height: auto !important;
                    }
                }

                .created-by {
                    display: flex;
                    align-items: center;
                }

                .summary {
                    @include display-flex();
                    @include justify-content(space-between);
                    width: 80%;
                }

                .employee-img {
                    @include background($size: cover);
                    display: inline-block;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                }

                .employee-name {
                    @include text-ellipsis();
                    display: inline-block;
                    width: calc(100% - 24px);
                    padding-left: 6px;
                    color: $color-black;
                }

                .score {
                    .score-done {
                        @include font-size(sm);
                        // font-family: $text-bold !important;
                        //padding-right: 6px;
                    }

                    .score-name {
                        font-family: $text;
                    }

                    .with-color {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 8px;

                        .score-color {
                            width: 8px;
                            height: 8px;
                            aspect-ratio: 1/1;
                            border-radius: 50%;
                        }
                    }

                    // .score-percent {
                    //     @include font-size(sm);
                    //     font-family: $text-bold;
                    // }
                }

                .na {
                    @include align-items();
                    @include border-radius(4px);
                    @include text-ellipsis();
                    @include background($image: img('error_main_s40.svg'), $size: 15px, $position: left 0px center);
                    @include font-size('sm');
                    padding: 3px 9px 3px 22px;
                    text-align: center;
                    // background-color: $color-primary-100;
                    // padding: 3px 9px 3px 30px;
                    font-family: $text-bold;
                    color: $color-primary-500;
                    width: min-content;
                }
                .v-data-table-header th:last-child {
                    padding-right: 60px; /* Adjust this value to provide more space */
                }
            }
        }
        .v-data-table-header th:last-child {
            padding-right: 60px; /* Adjust this value to provide more space */
        }
        &.with-trial {
            height: calc(100% - 20px - 55px);
        }
    }
}
</style>
